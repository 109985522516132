import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
	TooltipProvider,
} from "../ui/tooltip";
import React from "react";

const Footer = () => {
	return (
		<footer
			className="bg-gray-800 text-gray-300 py-10 md:py-20 w-full dark:bg-gray-900 dark:text-gray-300"
			style={{ boxShadow: "0 0 60px rgba(0, 0, 0, 0.5)" }}
		>
			<div className="flex flex-col md:flex-row w-full max-w-7xl mx-auto justify-between items-start px-4 md:px-0">
				{/* Logo and Copyright Section */}
				<div className="flex flex-col items-start mb-10 md:mb-0 md:mr-10">
					<img src="logo-path.png" alt="Company Logo" className="w-12 mb-4" />
					<p className="text-gray-300 text-sm">
						© 2024 Roadtrip, Inc.
						<br />
						All rights reserved.
					</p>
				</div>

				{/* Links Section */}
				<div className="flex flex-col md:flex-row flex-1 justify-between space-y-6 md:space-y-0 md:space-x-6">
					{/* Quick Links */}
					<div>
						<h4 className="text-gray-300 text-lg mb-4">Quick Links</h4>
						<ul className="space-y-3">
							<li>
								<a href="#" className="hover:text-yellow-400">
									Home
								</a>
							</li>
							<li>
								<a href="#" className="hover:text-yellow-400">
									About
								</a>
							</li>
							<li>
								<a href="#" className="hover:text-yellow-400">
									Features
								</a>
							</li>
							<li>
								<a href="#" className="hover:text-yellow-400">
									Contact
								</a>
							</li>
						</ul>
					</div>

					{/* Vertical Separator for desktop, hidden on mobile */}
					<Separator
						orientation="vertical"
						className="hidden md:block h-auto bg-slate-400 dark:bg-slate-600 w-[2px]"
					/>

					{/* Discover More */}
					<div>
						<h4 className="text-gray-300 text-lg mb-4">Discover More</h4>
						<ul className="space-y-3">
							<li>
								<a href="#" className="hover:text-yellow-400">
									Plan Your Trip
								</a>
							</li>
							<li>
								<a href="#" className="hover:text-yellow-400">
									Destinations
								</a>
							</li>
							<li>
								<a href="#" className="hover:text-yellow-400">
									Tips & Tricks
								</a>
							</li>
							<li>
								<a href="#" className="hover:text-yellow-400">
									FAQs
								</a>
							</li>
						</ul>
					</div>

					{/* Vertical Separator for desktop, hidden on mobile */}
					<Separator
						orientation="vertical"
						className="hidden md:block h-auto bg-slate-400 dark:bg-slate-600 w-[2px]"
					/>

					{/* Stay Connected with Tooltips */}
					<TooltipProvider>
						<div>
							<h4 className="text-gray-300 text-lg mb-4">Stay Connected</h4>
							<div className="space-y-3">
								<Tooltip>
									<TooltipTrigger asChild>
										<Button variant="ghost" className="w-full justify-start">
											<a href="#" className="hover:text-yellow-400">
												Facebook
											</a>
										</Button>
									</TooltipTrigger>
									<TooltipContent>
										<p>Follow us on Facebook</p>
									</TooltipContent>
								</Tooltip>

								<Tooltip>
									<TooltipTrigger asChild>
										<Button variant="ghost" className="w-full justify-start">
											<a href="#" className="hover:text-yellow-400">
												Instagram
											</a>
										</Button>
									</TooltipTrigger>
									<TooltipContent>
										<p>Follow us on Instagram</p>
									</TooltipContent>
								</Tooltip>

								<Tooltip>
									<TooltipTrigger asChild>
										<Button variant="ghost" className="w-full justify-start">
											<a href="#" className="hover:text-yellow-400">
												Twitter
											</a>
										</Button>
									</TooltipTrigger>
									<TooltipContent>
										<p>Follow us on Twitter</p>
									</TooltipContent>
								</Tooltip>

								<Tooltip>
									<TooltipTrigger asChild>
										<Button variant="ghost" className="w-full justify-start">
											<a href="#" className="hover:text-yellow-400">
												YouTube
											</a>
										</Button>
									</TooltipTrigger>
									<TooltipContent>
										<p>Follow us on YouTube</p>
									</TooltipContent>
								</Tooltip>
							</div>
						</div>
					</TooltipProvider>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
