import AppRoutes from "./Routes";
import { UserProvider } from "./UserContext";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer/Footer";
import CookieConsent from "react-cookie-consent";
import { ToastContainer } from "react-toastify";

function App() {
	return (
		<div>
			<CookieConsent
				location="bottom"
				cookieName="myAwesomeCookieName"
				style={{
					background:
						"linear-gradient(90deg, rgba(27,27,50,1) 0%, rgba(32,32,56,1) 100%)",
					color: "#fff",
					borderTop: "2px solid #4f86f7",
					boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
					fontFamily: "'Montserrat', sans-serif",
					padding: "20px",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
				buttonStyle={{
					background: "linear-gradient(135deg, #4f86f7 0%, #6c7ff7 100%)",
					color: "#fff",
					fontSize: "14px",
					border: "none",
					padding: "10px 25px",
					borderRadius: "50px",
					cursor: "pointer",
					transition: "transform 0.3s ease",
				}}
				buttonText="Sure, I agree"
				expires={150}
			>
				<p style={{ margin: 0, lineHeight: "1.5", fontSize: "14px" }}>
					We use cookies to improve your experience on our site. By continuing
					to use our site, you accept our use of cookies.{" "}
					<a
						href="/privacy-policy"
						style={{ color: "#6c7ff7", textDecoration: "underline" }}
					>
						Learn more
					</a>
				</p>
			</CookieConsent>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<UserProvider>
				<Router>
					<AppRoutes />
				</Router>
			</UserProvider>
			<Footer />
		</div>
	);
}

export default App;
