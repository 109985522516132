import React, { useState } from "react";

const StarRating = ({ rating, setRating }) => {
	const [hover, setHover] = useState(0);

	return (
		<div className="flex justify-center space-x-1">
			{[...Array(5)].map((star, index) => {
				index += 1;
				return (
					<button
						type="button"
						key={index}
						className={`text-2xl ${
							index <= (hover || rating) ? "text-yellow-400" : "text-gray-400"
						}`}
						onClick={() => setRating(index)}
						onMouseEnter={() => setHover(index)}
						onMouseLeave={() => setHover(rating)}
					>
						&#9733;
					</button>
				);
			})}
		</div>
	);
};

export default StarRating;
