import { useUser } from "../../UserContext";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ItinerariesPage = () => {
	const [itineraries, setItineraries] = useState([]);
	const { currentUser } = useUser();

	useEffect(() => {
		const fetchItineraries = async () => {
			if (!currentUser) {
				return;
			}

			try {
				const response = await axios.get(
					`https://www.getawai.online/itineraries?email=${currentUser.email}`,
					{
						withCredentials: true,
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				console.log("data");
				console.log(response.data);
				setItineraries(response.data);
			} catch (error) {
				console.error("Error fetching itineraries:", error);
			}
		};

		fetchItineraries();
	}, [currentUser]);

	const validItineraries = itineraries.filter(
		(itinerary) =>
			itinerary.destinationTitle &&
			itinerary.destinationTitle !== "Unknown Destination"
	);

	return (
		<div className="min-h-screen bg-slate-50 dark:bg-black text-black dark:text-white">
			<div className="container mx-auto p-4 bg-slate-50 dark:bg-black text-gray-800 dark:text-white min-h-screen">
				<h1 className="text-3xl text-black font-bold mb-6 dark:text-white">
					Itineraries
				</h1>
				{validItineraries.length > 0 ? (
					<ul>
						{validItineraries.map((itinerary) => (
							<li key={itinerary._id} className="mb-4">
								<Link
									to={{
										pathname: `/itinerary/${itinerary._id}`,
										state: { itineraryData: itinerary },
									}}
									className="block"
								>
									<Card className="hover:shadow-lg dark:bg-black">
										<CardHeader>
											<CardTitle className="text-xl font-semibold text-black dark:text-white">
												{`Trip to ${itinerary.destinationTitle}`}
											</CardTitle>
										</CardHeader>
										<CardContent>
											<p className="text-gray-700 dark:text-gray-300">
												Click to view details
											</p>
										</CardContent>
									</Card>
								</Link>
							</li>
						))}
					</ul>
				) : (
					<p>No saved itineraries yet.</p> // Show if there are no valid itineraries
				)}
			</div>
		</div>
	);
};

export default ItinerariesPage;
