import { Skeleton } from "../ui/skeleton";
import React, { useState } from "react";

const SearchSection = ({ onSearch }) => {
	const [searchQuery, setSearchQuery] = React.useState("");
	// eslint-disable-next-line
	const [suggestions, setSuggestions] = React.useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = (e) => {
		setIsLoading(true);
		e.preventDefault();
		onSearch(searchQuery); //pass search query the parent component
		setTimeout(() => setIsLoading(false), 1000);
	};

	const handleInputChange = (e) => {
		const value = e.target.value;
		setSearchQuery(value);
	};

	const clearSearch = () => {
		setSearchQuery("");
		setSuggestions([]);
	};

	return (
		<form
			className="relative w-full max-w-lg mx-auto flex flex-col md:flex-row"
			onSubmit={handleSubmit}
		>
			{isLoading ? (
				<Skeleton className="w-full h-12 md:h-10 mb-2" />
			) : (
				<>
					<div className="relative w-full md:flex-1">
						<input
							type="text"
							placeholder="Where to next?"
							value={searchQuery}
							onChange={handleInputChange}
							className="w-full h-12 p-4 pr-12 rounded-lg border border-gray-300 focus:ring focus:ring-green-500 focus:border-green-500 text-gray-700 dark:text-white dark:bg-gray-900 dark:border-gray-700"
						/>
						{searchQuery && (
							<button
								onClick={clearSearch}
								type="button"
								className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-transparent text-gray-500 dark:text-white"
							>
								✕
							</button>
						)}
					</div>
					<button
						type="submit"
						className="w-full md:w-auto h-12 mt-4 md:mt-0 md:ml-4 px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300"
					>
						Let's Go
					</button>
				</>
			)}
		</form>
	);
};

export default SearchSection;
