import { useUser } from "../../UserContext";
import DeleteAccount from "../DeleteAccount/DeleteAccount.jsx";
import TopDestinations from "../TopDestinations/TopDestinations.jsx";
import axios from "axios";
import { debounce } from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import "../../App.css";
import { Textarea } from "../ui/textarea";
import { toast } from "react-toastify";

//TODO
// Travel Preferences
// Accommodation Type: Preferences for hotels, hostels, apartments, or camping.
// Transportation Preferences: Preferred modes of travel, such as flying, trains, buses, or car rentals.
// Activity Interests: Types of activities the user enjoys, like hiking, museums, beach, nightlife, or cultural events.
// Hobbies: Collecting, sports, reading, music, gaming, etc. This could help in suggesting activities or destinations.
// Gamification: Encourage completion of profile details with badges, rewards, or progress bars.
// Festivals and Seasonal Events: Interest in festivals or events, like cherry blossoms in Japan or the Northern Lights in Scandinavia.

const foodOptions = [
	{ value: "vegan", label: "Vegan" },
	{ value: "vegetarian", label: "Vegetarian" },
	{ value: "glutenFree", label: "Gluten-Free" },
	{ value: "halal", label: "Halal" },
	{ value: "kosher", label: "Kosher" },
];

function UserProfile() {
	const navigate = useNavigate();
	const { setCurrentUser } = useUser();
	const [userData, setUserData] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [bio, setBio] = useState("");
	const [socialLinks, setSocialLinks] = useState({
		instagram: "",
		tiktok: "",
		facebook: "",
	});
	const [foodPreferences] = useState("");
	const [selectedFoodPreferences, setSelectedFoodPreferences] = useState([]);
	const [isDarkMode, setIsDarkMode] = useState(false);

	useEffect(() => {
		const handleThemeChange = () => {
			setIsDarkMode(document.documentElement.classList.contains("dark"));
		};

		// Check on mount
		handleThemeChange();

		// Listen for theme changes
		const observer = new MutationObserver(handleThemeChange);
		observer.observe(document.documentElement, {
			attributes: true,
			attributeFilter: ["class"],
		});

		// Cleanup observer when component unmounts
		return () => observer.disconnect();
	}, []);

	const handleFoodPreferenceChange = (selectedOptions) => {
		setSelectedFoodPreferences(selectedOptions);
		const preferencesToSend = selectedOptions.map((option) => option.value);
		updateFoodPreferences(preferencesToSend); //debounced call
	};

	//function when a new option is created by the user
	const onCreateOption = (inputValue) => {
		const newOption = { label: inputValue, value: inputValue };
		const updatedPreferences = [...selectedFoodPreferences, newOption];
		setSelectedFoodPreferences(updatedPreferences);

		const preferencesToSend = updatedPreferences.map((option) => option.value);
		updateFoodPreferences(preferencesToSend); //the same debounced function used in handleFoodPreferenceChange
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const updateFoodPreferences = useCallback(
		debounce(async (foodPreferences) => {
			try {
				const previousPreferences = selectedFoodPreferences.map(
					(option) => option.value
				);

				// Find out added preferences
				const addedPreferences = foodPreferences.filter(
					(pref) => !previousPreferences.includes(pref)
				);

				// Find out removed preferences
				const removedPreferences = previousPreferences.filter(
					(pref) => !foodPreferences.includes(pref)
				);

				// Detect if all preferences are cleared
				const allRemoved =
					foodPreferences.length === 0 && previousPreferences.length > 0;

				// Show toast for added preferences
				if (addedPreferences.length > 0) {
					const lastAddedPreference =
						addedPreferences[addedPreferences.length - 1];
					if (lastAddedPreference !== undefined) {
						toast.success(`${lastAddedPreference} added successfully.`);
					}
				}

				// Show toast for removing all preferences at once
				if (allRemoved) {
					toast.info("All preferences removed successfully.");
				} else if (removedPreferences.length > 0) {
					// Show toast for removed preferences only if it's not the "remove all" case
					const lastRemovedPreference =
						removedPreferences[removedPreferences.length - 1];
					if (lastRemovedPreference !== undefined) {
						toast.info(`${lastRemovedPreference} removed successfully.`);
					}
				}

				// Make the API call to update preferences
				await axios.post(
					"https://www.getawai.online/update_food_preferences",
					{
						foodPreferences,
					},
					{
						withCredentials: true,
					}
				);

				console.log("Food preferences updated successfully.");
			} catch (error) {
				toast.error("Failed to update food preferences. Please try again.");
				console.error("Failed to update food preferences:", error);
			}
		}, 300),
		[selectedFoodPreferences] // Dependency array to track changes
	);

	useEffect(() => {
		const fetchProfileData = async () => {
			try {
				const response = await axios.get("https://www.getawai.online/profile", {
					withCredentials: true,
				});

				setUserData(response.data);
				//response.data contains bio socialLinks foodPreferences
				setBio(response.data.bio || "");
				console.log(response.data);
				setSocialLinks(
					response.data.socialLinks || {
						instagram: "",
						twitter: "",
						facebook: "",
					}
				);
				//transform and set food preferences for CreatableSelect
				const transformedFoodPreferences = response.data.foodPreferences.map(
					(pref) => ({
						label: pref,
						value: pref,
					})
				);
				setSelectedFoodPreferences(transformedFoodPreferences);
			} catch (error) {
				console.error("Error fetching profile data:", error);
				toast.error("Failed to generate itinerary. Please try again.");
				navigate("/login");
			}
		};
		fetchProfileData();
	}, [navigate]);

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const updateProfile = async (event) => {
		event.preventDefault();
		setLoading(true);
		const formData = new FormData();
		if (selectedFile) {
			formData.append("photo", selectedFile);
		}
		// Include bio, social links, and food preferences in the form data
		formData.append("bio", bio);
		formData.append("socialLinks", JSON.stringify(socialLinks));
		// formData.append("foodPreferences", foodPreferences);
		const foodPreferencesToSend = selectedFoodPreferences.map(
			(option) => option.value
		);
		formData.append("foodPreferences", JSON.stringify(foodPreferencesToSend));

		try {
			const response = await axios.post(
				"https://www.getawai.online/update_profile",
				formData,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			toast.success("Profile updated successfully!");
			const updatedUserData = {
				...userData,
				photo_url: response.data.photo_url,
				// Update bio, socialLinks, and foodPreferences based on response
				bio: bio,
				socialLinks: socialLinks,
				foodPreferences: foodPreferences,
			};
			setUserData(updatedUserData); // Update the local user data state
			setCurrentUser(updatedUserData); // Update user context if needed
		} catch (error) {
			toast.error("Failed to generate itinerary. Please try again.");
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	if (loading) return <div>Loading...</div>;

	return (
		<div className="bg-white dark:bg-black text-gray-900 dark:text-gray-100 min-h-screen">
			<div className="container mx-auto p-4 max-w-4xl">
				<h2 className="text-3xl font-semibold text-gray-800 dark:text-gray-200 mb-6 text-center">
					User Profile
				</h2>
				{userData && (
					<>
						<div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6 mb-6">
							{userData.photo_url && (
								<label htmlFor="photo-upload" className="cursor-pointer">
									<img
										src={userData.photo_url}
										alt="User profile"
										className="w-32 h-32 rounded-full object-cover shadow-lg"
									/>
								</label>
							)}
							<div className="text-center md:text-left">
								<p className="text-lg font-semibold text-gray-800 dark:text-gray-200">
									Email: {userData.email}
								</p>
								<p className="text-lg font-semibold text-gray-800 dark:text-gray-200">
									Username: {userData.username}
								</p>
								<p className="text-lg font-semibold text-gray-800 dark:text-gray-200">
									Name: {userData.name}
								</p>
							</div>
						</div>
						<input
							type="file"
							id="photo-upload"
							onChange={handleFileChange}
							className="hidden"
						/>
						<div className="mb-4">
							<label className="text-gray-700 dark:text-gray-300">Bio:</label>
							<Textarea
								value={bio}
								onChange={(e) => setBio(e.target.value)}
								className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-black dark:text-white"
								placeholder="Tell us something about yourself..."
							/>
						</div>
						<div className="mb-4">
							<label className="text-gray-700 dark:text-gray-300">
								Instagram:
							</label>
							<input
								type="text"
								value={socialLinks.instagram}
								onChange={(e) =>
									setSocialLinks({
										...socialLinks,
										instagram: e.target.value,
									})
								}
								className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-black dark:text-white"
								placeholder="Instagram profile link"
							/>
						</div>
						<div className="mb-4">
							<label className="text-gray-700 dark:text-gray-300">
								TikTok:
							</label>
							<input
								type="text"
								value={socialLinks.tiktok}
								onChange={(e) =>
									setSocialLinks({
										...socialLinks,
										tiktok: e.target.value,
									})
								}
								className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-black dark:text-white"
								placeholder="TikTok profile link"
							/>
						</div>
						<div className="mb-4">
							<label className="text-gray-700 dark:text-gray-300">
								Food Preferences:
							</label>
							<CreatableSelect
								isMulti
								name="foodPreferences"
								options={foodOptions}
								className="basic-multi-select"
								classNamePrefix="select"
								placeholder="ex: Vegan, Gluten-Free, etc"
								onChange={handleFoodPreferenceChange}
								value={selectedFoodPreferences}
								onCreateOption={onCreateOption}
								styles={{
									control: (provided, state) => ({
										...provided,
										backgroundColor: isDarkMode ? "#000" : "#fff",
										borderColor: state.isFocused
											? isDarkMode
												? "#444"
												: "#ccc"
											: isDarkMode
											? "#555"
											: "#ddd",
										color: isDarkMode ? "#fff" : "#000",
									}),
									menu: (provided) => ({
										...provided,
										backgroundColor: isDarkMode ? "#000" : "#fff",
									}),
									option: (provided, state) => ({
										...provided,
										backgroundColor: state.isSelected
											? isDarkMode
												? "#222"
												: "#eee"
											: isDarkMode
											? "#000"
											: "#fff",
										color: isDarkMode
											? state.isSelected
												? "#fff"
												: "#fff" // Ensure text color is always white in dark mode
											: state.isSelected
											? "#000"
											: "#000", // Ensure text color is black in light mode
										"&:hover": {
											backgroundColor: isDarkMode ? "#333" : "#ddd",
											color: isDarkMode ? "#fff" : "#000", // Ensure hover text color is correct
										},
									}),
									singleValue: (provided) => ({
										...provided,
										color: isDarkMode ? "#fff" : "#000",
									}),
									multiValue: (provided) => ({
										...provided,
										backgroundColor: isDarkMode ? "#222" : "#eee",
									}),
									multiValueLabel: (provided) => ({
										...provided,
										color: isDarkMode ? "#fff" : "#000",
									}),
									multiValueRemove: (provided) => ({
										...provided,
										color: isDarkMode ? "#fff" : "#000",
										"&:hover": {
											backgroundColor: isDarkMode ? "#333" : "#ddd",
											color: "#fff",
										},
									}),
								}}
							/>
						</div>
						<TopDestinations showOnlyFavorites={true} />
						<form encType="multipart/form-data" className="space-y-4">
							<button
								type="button"
								onClick={updateProfile}
								className="w-full md:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline dark:bg-blue-600 dark:hover:bg-blue-800"
							>
								Update Profile
							</button>
						</form>
						<div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
							<Link to="/group-management" className="block text-center">
								<button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline dark:bg-blue-600 dark:hover:bg-blue-800">
									Groupchats
								</button>
							</Link>
							<Link to="/itineraries" className="block text-center">
								<button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline dark:bg-blue-600 dark:hover:bg-blue-800">
									My Trips
								</button>
							</Link>
						</div>
						<br></br>
						<div>
							<DeleteAccount></DeleteAccount>
						</div>
					</>
				)}
			</div>
			{/* <Footer></Footer> */}
		</div>
	);
}

export default UserProfile;
