import { useUser } from "../../UserContext";
import axios from "axios";
import React, { useState, useEffect } from "react";
import "./UserReviews.css";
import StarRating from "../StarRating/StarRating";
import { Button } from "../ui/button";
import { toast } from "react-toastify";

// Import Shadcn Toast components
const UserReviews = () => {
	const { currentUser } = useUser();
	const [reviews, setReviews] = useState([]);
	const [newReview, setNewReview] = useState({
		name: currentUser?.name || "",
		review: "",
		rating: 0,
		image: currentUser?.photo_url || "/images/default-user.png",
	});

	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const fetchReviews = async () => {
			try {
				const response = await axios.get("https://www.getawai.online/api/reviews");
				setReviews(response.data);
			} catch (error) {
				console.error("Failed to fetch reviews:", error);
			}
		};

		fetchReviews();
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewReview({ ...newReview, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!currentUser) {
			toast.error("You need to be logged in to submit a review.");
			return;
		}

		if (newReview.review && newReview.rating) {
			try {
				const response = await axios.post(
					"https://www.getawai.online/api/reviews",
					{
						review: newReview.review,
						rating: newReview.rating,
						name: currentUser.name,
						image: currentUser.photo_url || "/images/default-user.png",
					},
					{
						withCredentials: true,
					}
				);
				setReviews([...reviews, response.data]);
				setNewReview({
					name: currentUser.name || "",
					review: "",
					rating: 0,
					image: currentUser.photo_url || "/images/default-user.png",
				});
				setIsModalOpen(false);
				toast.success("Review added successfully! Thank you!");
			} catch (error) {
				console.error("Failed to add review:", error);
				toast.error("Failed to add review.");
			}
		}
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<section className="user-reviews py-16 bg-gray-100">
			<h2 className="text-3xl font-bold text-center text-gray-800 dark:text-gray-100 mb-10">
				What Our Users Say
			</h2>
			<div className="reviews-list flex flex-wrap gap-6 justify-center">
				{reviews.map((review, index) => (
					<div
						key={index}
						className="review-card bg-white shadow-lg rounded-lg p-6 max-w-sm w-full transform transition-transform duration-300 hover:scale-105"
					>
						<img
							src={review.image}
							alt={review.name}
							className="review-image w-16 h-16 rounded-full object-cover mx-auto mb-4"
						/>
						<h3 className="text-xl font-semibold text-center text-gray-700 dark:text-gray-200 mb-2">
							{review.name}
						</h3>
						<p className="review-text text-gray-600 dark:text-gray-300 mb-4 text-center">
							"{review.review}"
						</p>
						<div className="rating flex justify-center">
							{Array(review.rating)
								.fill("⭐")
								.map((star, i) => (
									<span key={i} className="text-yellow-400 text-lg">
										{star}
									</span>
								))}
						</div>
					</div>
				))}
			</div>

			{currentUser && (
				<button
					onClick={openModal}
					className="mt-8 bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors duration-300 mx-auto block"
				>
					Add Review
				</button>
			)}

			{isModalOpen && (
				<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
					<div className="modal bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg max-w-lg w-full">
						<h3 className="text-2xl font-bold text-center text-gray-700 dark:text-gray-200 mb-4">
							Add Your Review
						</h3>
						<form onSubmit={handleSubmit} className="space-y-6">
							<input
								type="text"
								name="name"
								placeholder="Your Name"
								value={newReview.name}
								readOnly
								className="w-full p-3 border rounded-lg dark:bg-gray-700 dark:text-gray-100"
								required
							/>
							<textarea
								name="review"
								placeholder="Your Review"
								value={newReview.review}
								onChange={handleInputChange}
								className="w-full p-3 border rounded-lg dark:bg-gray-700 dark:text-gray-100"
								required
							/>
							<StarRating
								rating={newReview.rating}
								setRating={(value) =>
									setNewReview({ ...newReview, rating: value })
								}
							/>
							<div className="flex justify-between">
								<Button
									type="submit"
									className="bg-green-600 text-white py-3 px-6 rounded-lg hover:bg-green-700 transition-colors duration-300"
								>
									Submit Review
								</Button>
								<Button
									type="button"
									className="bg-red-600 text-white py-3 px-6 rounded-lg hover:bg-red-700 transition-colors duration-300"
									onClick={closeModal}
								>
									Cancel
								</Button>
							</div>
						</form>
					</div>
				</div>
			)}
		</section>
	);
};

export default UserReviews;
