// AdminPage.js

import axios from "axios";
import React, { useState, useEffect } from "react";
import "../AdminPage/AdminPage.css";
import { Input } from "../ui/input";
import { Skeleton } from "../ui/skeleton";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import Draggable from "react-draggable";
import { toast } from "react-toastify";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
);
const AdminPage = () => {
	// State for the new item inputs
	const [newAccommodation, setNewAccommodation] = useState("");
	const [newInterest, setNewInterest] = useState("");
	const [newDriveStyle, setNewDriveStyle] = useState("");
	const [newFuelType, setNewFuelType] = useState("");
	const [topCities, setTopCities] = useState([]);
	const [topLikedCities, setTopLikedCities] = useState([]);
	const [userStats, setUserStats] = useState({ total_users: 0, new_users: 0 });

	// State for lists
	const [accommodations, setAccommodations] = useState([]);
	const [interests, setInterests] = useState([]);
	const [driveStyles, setDriveStyles] = useState([]);
	const [fuelTypes, setFuelTypes] = useState([]);

	// State for chart data
	const [userStatsChartData, setUserStatsChartData] = useState(null);
	const [topCitiesChartData, setTopCitiesChartData] = useState(null);
	const [topLikedCitiesChartData, setTopLikedCitiesChartData] = useState(null);

	const [addItemError, setAddItemError] = useState("");

	// Loading states for skeletons
	const [loadingAccommodations, setLoadingAccommodations] = useState(true);
	const [loadingInterests, setLoadingInterests] = useState(true);
	const [loadingDriveStyles, setLoadingDriveStyles] = useState(true);
	const [loadingFuelTypes, setLoadingFuelTypes] = useState(true);

	useEffect(() => {
		const fetchItems = async () => {
			try {
				const accommodationsResp = await axios.get(
					"https://www.getawai.online/api/getAccommodations",
					{ withCredentials: true }
				);
				const parsedAccommodations = accommodationsResp.data.map((item) =>
					JSON.parse(item)
				);
				setAccommodations(parsedAccommodations);
				setLoadingAccommodations(false);

				const interestsResp = await axios.get(
					"https://www.getawai.online/api/getInterests",
					{ withCredentials: true }
				);
				const parsedInterests = interestsResp.data.map((item) =>
					JSON.parse(item)
				);
				setInterests(parsedInterests);
				setLoadingInterests(false);

				const topCitiesResp = await axios.get(
					"https://www.getawai.online/admin/top-cities",
					{ withCredentials: true }
				);
				setTopCities(topCitiesResp.data.filter((city) => city._id !== null));

				const topLikedCitiesResp = await axios.get(
					"https://www.getawai.online/admin/top-liked-cities",
					{ withCredentials: true }
				);
				setTopLikedCities(topLikedCitiesResp.data);

				const userStatsResp = await axios.get(
					"https://www.getawai.online/admin/user-stats",
					{ withCredentials: true }
				);
				setUserStats(userStatsResp.data);

				const driveStylesResp = await axios.get(
					"https://www.getawai.online/api/getDrivingStyles",
					{ withCredentials: true }
				);
				const parsedDriveStyles = driveStylesResp.data.map((item) =>
					JSON.parse(item)
				);
				setDriveStyles(parsedDriveStyles);
				setLoadingDriveStyles(false);

				const fuelTypesResp = await axios.get(
					"https://www.getawai.online/api/getFuelTypes",
					{ withCredentials: true }
				);
				const parsedFuelTypes = fuelTypesResp.data.map((item) =>
					JSON.parse(item)
				);
				setFuelTypes(parsedFuelTypes);
				setLoadingFuelTypes(false);

				prepareChartData(
					userStatsResp.data,
					topCitiesResp.data,
					topLikedCitiesResp.data
				);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchItems();
	}, []);

	//GENERIC ADD FOR ANY ITEM

	const handleAddItemOptimistically = async (type, name) => {
		// Prevent adding if name is empty
		if (!name.trim()) {
			toast.error("Name cannot be empty."); // Display error using toast
			return;
		}

		// Normalize the name to lowercase for comparison
		const normalizedName = name.toLowerCase();

		// Prepare the state update based on the type
		const newItem = { name };
		let isDuplicate = false;

		if (
			type === "accommodations" &&
			!accommodations.some((item) => item.name.toLowerCase() === normalizedName)
		) {
			setAccommodations([...accommodations, newItem]);
			setNewAccommodation("");
		} else if (
			type === "interests" &&
			!interests.some((item) => item.name.toLowerCase() === normalizedName)
		) {
			setInterests([...interests, newItem]);
			setNewInterest("");
		} else if (
			type === "driving" &&
			!driveStyles.some((item) => item.name.toLowerCase() === normalizedName)
		) {
			setDriveStyles([...driveStyles, newItem]);
			setNewDriveStyle("");
		} else if (
			type === "fueltypes" && // Ensure the type is 'fueltypes'
			!fuelTypes.some((item) => item.name.toLowerCase() === normalizedName)
		) {
			setFuelTypes([...fuelTypes, newItem]);
			setNewFuelType("");
		} else {
			isDuplicate = true;
			toast.error(`Error: ${name} already exists in ${type}.`); // Display error using toast
		}

		// If item is a duplicate, don't proceed with the API call
		if (isDuplicate) return;

		try {
			// Attempt to add the item on the server
			const response = await axios.post(
				`https://www.getawai.online/admin/${type}`,
				{ name },
				{ withCredentials: true }
			);
			if (!response || !response.data) {
				throw new Error("Invalid response from the server");
			}
			toast.success(`${type} added successfully: ${name}`); // Display success message using toast
			setAddItemError(""); // Clear any error message
			// Optionally, refresh items list here
		} catch (error) {
			console.error(`Failed to add ${type}:`, error);
			toast.error(
				error.response?.data?.error ||
					`Failed to add ${type}. Please try again.`
			); // Display error using toast
			// Revert UI changes in case of error
			if (type === "accommodations")
				setAccommodations(accommodations.filter((item) => item.name !== name));
			else if (type === "interests")
				setInterests(interests.filter((item) => item.name !== name));
			else if (type === "driving")
				setDriveStyles(driveStyles.filter((item) => item.name !== name));
			else if (type === "fueltypes")
				setFuelTypes(fuelTypes.filter((item) => item.name !== name));
		}
	};

	//SPECIFIC FUNCTIONS TO ADD EACH ITEM

	// const handleAddAccommodation = async (e) => {
	//   e.preventDefault();
	//   try {
	//     const url = 'http://localhost:5000/admin/accommodations';
	//     const formData = { name: newAccommodation };
	//     await axios.post(url, formData, { withCredentials: true });
	//     alert('Accommodation added successfully!');
	//     fetchItems();
	//     setNewAccommodation('');
	//   } catch (error) {
	//     console.error('Failed to add accommodation:', error);
	//     // Display a user-friendly error message
	//   }
	// };

	// const handleAddTransport = async (e) => {
	//   e.preventDefault();
	//   try {
	//       const url = 'http://localhost:5000/admin/transports';
	//       const formData = { name: newTransport };
	//       await axios.post(url, formData, { withCredentials: true });
	//       alert('Transport added successfully!');
	//       setNewTransport('');
	//     } catch (error) {
	//       console.error('Failed to add transport:', error);
	//       // Display a user-friendly error message
	//     }
	// };

	// const handleAddInterest = async (e) => {
	//   e.preventDefault();
	//   try {
	//       const url = 'http://localhost:5000/admin/interests';
	//       const formData = { name: newInterest };
	//       await axios.post(url, formData, { withCredentials: true });
	//       alert('Interest added successfully!');
	//       setNewInterest('');
	//     } catch (error) {
	//       console.error('Failed to add interest:', error);
	//       // Display a user-friendly error message
	//     }
	// };

	//SPECIFIC FUNCTIONS FOR DELETING EACH ITEM

	// const handleDeleteAccommodation = async (name) => {
	//   try {
	//     const response = await axios.delete(`http://localhost:5000/admin/accommodations/${name}`, {
	//       withCredentials: true
	//     });
	//     console.log(response.data.message);
	//     fetchItems();
	//   } catch (error) {
	//     console.error('Failed to delete accommodation:', error.response.data);
	//   }
	// };

	// const handleDeleteAccommodationOptimistically = async (type, name) => {
	//   const previousAccommodations = [...accommodations]; // Store the current state
	//   // Optimistically remove the accommodation from the UI
	//   const updatedAccommodations = accommodations.filter(item => item.name !== name);
	//   setAccommodations(updatedAccommodations);

	//   try {
	//     // Attempt to delete the accommodation on the server
	//     await axios.delete(`http://localhost:5000/admin/delete/accommodations/${name}`, { withCredentials: true });
	//     console.log(`Accommodation ${name} deleted successfully.`);
	//   } catch (error) {
	//     console.error(`Failed to delete accommodation:`, error.response.data);
	//     // Revert the change in case of an error
	//     setAccommodations(previousAccommodations);
	//   }
	// };

	const prepareChartData = (userStats, topCities, topLikedCities) => {
		// Prepare User Statistics Chart Data
		const userStatsData = {
			labels: ["Total Users", "New Users (Last 30 Days)"],
			datasets: [
				{
					label: "Users",
					data: [userStats.total_users, userStats.new_users],
					backgroundColor: ["#42a5f5", "#66bb6a"],
				},
			],
		};
		setUserStatsChartData(userStatsData);

		// Prepare Top Cities Chart Data
		const citiesLabels = topCities.map((city) => city._id);
		const citiesData = topCities.map((city) => city.count);
		const colors = citiesLabels.map(
			(_, index) => `hsl(${index * 36}, 70%, 50%)`
		);

		const generateColors = (length) => {
			const colors = [];
			for (let i = 0; i < length; i++) {
				colors.push(`hsl(${(i * 360) / length}, 70%, 50%)`);
			}
			return colors;
		};

		const topCitiesData = {
			labels: citiesLabels,
			datasets: [
				{
					label: "Number of Visits",
					data: citiesData,
					backgroundColor: colors,
				},
			],
		};
		setTopCitiesChartData(topCitiesData);
		console.log(
			"top cities =============================================================== "
		);
		console.log(topCitiesData);

		// Prepare Top Liked Cities Chart Data
		const likedCitiesLabels = topLikedCities.map((city) => city.name);
		const likedCitiesData = topLikedCities.map((city) => city.count);
		const topLikedCitiesDataColors = generateColors(likedCitiesLabels.length);

		const topLikedCitiesData = {
			labels: likedCitiesLabels,
			datasets: [
				{
					label: "Number of Likes",
					data: likedCitiesData,
					backgroundColor: topLikedCitiesDataColors,
				},
			],
		};
		setTopLikedCitiesChartData(topLikedCitiesData);
	};

	const handleDeleteItemOptimistically = async (type, name) => {
		//current state
		const previousItems = {
			accommodations: [...accommodations],
			interests: [...interests],
			driveStyles: [...driveStyles],
			fuelTypes: [...fuelTypes],
		};

		//optimistically update the UI => removing the item before the actual deletion
		if (type === "accommodations") {
			setAccommodations((prev) => prev.filter((item) => item.name !== name));
		} else if (type === "interests") {
			setInterests((prev) => prev.filter((item) => item.name !== name));
		} else if (type === "driving") {
			setDriveStyles((prev) => prev.filter((item) => item.name !== name));
		} else if (type === "fueltypes") {
			setFuelTypes((prev) => prev.filter((item) => item.name !== name));
		}

		try {
			//delete the item on the server
			await axios.delete(
				`https://www.getawai.online/admin/delete/${type}/${name}`,
				{
					withCredentials: true,
				}
			);
			console.log(`${type} ${name} deleted successfully.`);
		} catch (error) {
			console.error(`Failed to delete ${type}:`, error.response.data);
			// revert the UI update in case of an error
			if (type === "accommodations") {
				setAccommodations(previousItems.accommodations);
			} else if (type === "interests") {
				setInterests(previousItems.interests);
			} else if (type === "driving") {
				setDriveStyles(previousItems.driveStyles);
			} else if (type === "fueltypes") {
				setFuelTypes(previousItems.fuelTypes);
			}
		}
	};

	return (
		<div className="admin-page-container min-h-screen flex flex-col bg-gray-100 dark:bg-black text-gray-900 dark:text-gray-100 p-8">
			<h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
			<div className="charts-container">
				<Draggable>
					<div className="chart-item mb-8 bg-white border border-black dark:bg-black dark:border-gray-400 rounded-lg p-4">
						<h2 className="text-xl font-semibold mb-4">User Statistics</h2>
						{userStatsChartData && userStatsChartData.labels ? (
							<Bar
								data={userStatsChartData}
								options={{ maintainAspectRatio: false }}
							/>
						) : (
							<Skeleton className="h-40" /> // Render skeleton if data is not loaded
						)}
					</div>
				</Draggable>

				<Draggable>
					<div className="chart-item mb-8 bg-white border border-black dark:bg-black dark:border-gray-400 rounded-lg p-4">
						<h2 className="text-xl font-semibold mb-4">
							Top Cities Users are using the app from
						</h2>
						{topCitiesChartData && topCitiesChartData.labels ? (
							<Doughnut
								data={topCitiesChartData}
								options={{ maintainAspectRatio: false }}
							/>
						) : (
							<Skeleton className="h-40" />
						)}
					</div>
				</Draggable>

				<Draggable>
					<div className="chart-item mb-8 bg-white border border-black dark:bg-black dark:border-gray-400 rounded-lg p-4">
						<h2 className="text-xl font-semibold mb-4">Top Liked Cities</h2>
						{topLikedCitiesChartData && topLikedCitiesChartData.labels ? (
							<Doughnut
								data={topLikedCitiesChartData}
								options={{ maintainAspectRatio: false }}
							/>
						) : (
							<Skeleton className="h-40" />
						)}
					</div>
				</Draggable>
			</div>
			<div className="admin-section mb-8 bg-white border border-black dark:bg-black dark:border-white rounded-lg p-4">
				{addItemError && <div className="error-message">{addItemError}</div>}
				<h2 className="text-xl text-black dark:text-white font-semibold mb-4">
					Manage Accommodations
				</h2>
				<form
					className="flex items-center space-x-4"
					onSubmit={(e) => {
						e.preventDefault();
						handleAddItemOptimistically(
							"accommodations",
							newAccommodation,
							setAccommodations,
							setNewAccommodation
						);
					}}
				>
					<Input
						type="text"
						className="input-base w-full px-4 py-2 border rounded-lg bg-white dark:bg-black dark:border-white dark:text-white"
						value={newAccommodation}
						onChange={(e) => setNewAccommodation(e.target.value)}
						placeholder="New Accommodation"
					/>
					<button
						type="submit"
						className="btn-base bg-blue-500 text-white dark:text-black px-4 py-2 rounded-lg hover:bg-blue-700"
					>
						Add
					</button>
				</form>
				<ul className="list-disc pl-5 mt-2">
					{loadingAccommodations ? (
						<Skeleton className="h-6 w-full" count={5} />
					) : (
						accommodations.map((item, index) => (
							<li key={index} className="flex justify-between items-center">
								{item.name}
								<button
									onClick={() =>
										handleDeleteItemOptimistically("accommodations", item.name)
									}
									className="btn-delete text-white dark:text-black"
								>
									Delete
								</button>
							</li>
						))
					)}
				</ul>
			</div>

			<div className="admin-section mb-8 bg-white border border-black dark:bg-black dark:border-white rounded-lg p-4">
				<h2 className="text-xl text-black dark:text-white font-semibold">
					Manage Interests
				</h2>
				<form
					className="flex items-center space-x-4"
					onSubmit={(e) => {
						e.preventDefault();
						handleAddItemOptimistically(
							"interests",
							newInterest,
							setInterests,
							setNewInterest
						);
					}}
				>
					<Input
						type="text"
						className="input-base w-full px-4 py-2 border rounded-lg bg-white dark:bg-black dark:border-white dark:text-white"
						placeholder="New interest"
						value={newInterest}
						onChange={(e) => setNewInterest(e.target.value)}
					/>
					<button
						type="submit"
						className="btn-base bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
					>
						Add
					</button>
				</form>
				<ul className="list-disc pl-5 mt-2">
					{loadingInterests ? (
						<Skeleton className="h-6 w-full" count={5} />
					) : (
						interests.map((item, index) => (
							<li key={index} className="flex justify-between items-center">
								{item.name}
								<button
									onClick={() =>
										handleDeleteItemOptimistically("interests", item.name)
									}
									className="btn-delete text-white dark:text-black"
								>
									Delete
								</button>
							</li>
						))
					)}
				</ul>
			</div>

			<div className="admin-section mb-8 bg-white border border-black dark:bg-black dark:border-white rounded-lg p-4">
				<h2 className="text-xl text-black dark:text-white font-semibold mb-4">
					Manage Driving Styles
				</h2>
				<form
					className="flex items-center space-x-4"
					onSubmit={(e) => {
						e.preventDefault();
						handleAddItemOptimistically(
							"driving",
							newDriveStyle,
							setDriveStyles,
							setNewDriveStyle
						);
					}}
				>
					<Input
						type="text"
						className="input-base w-full px-4 py-2 border rounded-lg bg-white dark:bg-black dark:border-white dark:text-white"
						value={newDriveStyle}
						onChange={(e) => setNewDriveStyle(e.target.value)}
						placeholder="New Driving Style"
					/>
					<button
						type="submit"
						className="btn-base bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
					>
						Add
					</button>
				</form>
				<ul className="list-disc pl-5 mt-2">
					{loadingDriveStyles ? (
						<Skeleton className="h-6 w-full" count={5} />
					) : (
						driveStyles.map((style, index) => (
							<li key={index} className="flex justify-between items-center">
								{style.name}
								<button
									onClick={() =>
										handleDeleteItemOptimistically("driving", style.name)
									}
									className="btn-delete text-white dark:text-black"
								>
									Delete
								</button>
							</li>
						))
					)}
				</ul>
			</div>

			<div className="admin-section mb-8 bg-white border border-black dark:bg-black dark:border-white rounded-lg p-4">
				<h2 className="text-xl text-black dark:text-white font-semibold mb-4">
					Manage Fuel Types
				</h2>
				<form
					className="flex items-center space-x-4"
					onSubmit={(e) => {
						e.preventDefault();
						handleAddItemOptimistically(
							"fueltypes",
							newFuelType,
							setFuelTypes,
							setNewFuelType
						);
					}}
				>
					<Input
						type="text"
						className="input-base w-full px-4 py-2 border rounded-lg bg-white dark:bg-black dark:border-white dark:text-white"
						placeholder="New Fuel Type"
						value={newFuelType}
						onChange={(e) => setNewFuelType(e.target.value)}
					/>
					<button
						type="submit"
						className="btn-base bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
					>
						Add
					</button>
				</form>
				<ul className="list-disc pl-5 mt-4">
					{loadingFuelTypes ? (
						<Skeleton className="h-6 w-full" count={5} />
					) : (
						fuelTypes.map((item, index) => (
							<li key={index} className="flex justify-between items-center">
								{item.name}
								<button
									onClick={() =>
										handleDeleteItemOptimistically("fueltypes", item.name)
									}
									className="btn-delete text-white dark:text-black"
								>
									Delete
								</button>
							</li>
						))
					)}
				</ul>
			</div>

			<div className="admin-section mb-8 bg-white border border-black dark:bg-black dark:border-white rounded-lg p-4">
				<h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
					Top Cities Users are using the app from
				</h2>
				<ul className="list-disc pl-5 mt-2 text-black dark:text-white">
					{topCities.map((city, index) => (
						<li key={index} className="flex justify-between items-center">
							<span>{city._id}</span>
							<span className="text-black dark:text-white">
								{city.count} visits
							</span>
						</li>
					))}
				</ul>
			</div>

			<div className="admin-section mb-8 bg-white border border-black dark:bg-black dark:border-white rounded-lg p-4">
				<h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
					Top Liked Cities
				</h2>
				<ul className="list-disc pl-5 mt-2 text-gray-800 dark:text-gray-300">
					{topLikedCities.map((city, index) => (
						<li key={index} className="flex justify-between items-center">
							<span>{city.name}</span>
							<span className="text-black dark:text-white">
								{city.count} likes
							</span>
						</li>
					))}
				</ul>
			</div>
			<div className="admin-section mb-8 bg-white border border-black dark:bg-black dark:border-white rounded-lg p-4">
				<h2 className="text-xl font-semibold text-black dark:text-white">
					User Statistics
				</h2>
				<ul className="list-disc pl-5 mt-2 text-black dark:text-white">
					<li>
						Total Users:{" "}
						<span className="text-black dark:text-white">
							{userStats.total_users}
						</span>
					</li>
					<li>
						New Users (last 30 days):{" "}
						<span className="text-black dark:text-white">
							{userStats.new_users}
						</span>
					</li>
					<li>
						Subscribers:{" "}
						<span className="text-black dark:text-white">
							{userStats.subscribers}
						</span>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default AdminPage;
