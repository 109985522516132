import { Textarea } from "../ui/textarea";
import "./message.css";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { io } from "socket.io-client";

const GroupChat = () => {
	const { groupId } = useParams();
	const navigate = useNavigate();
	const [notes, setNotes] = useState([]);
	const [newNote, setNewNote] = useState("");
	const [members, setMembers] = useState([]);
	const [admins, setAdmins] = useState([]);
	const [currentUser, setCurrentUser] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showMembers, setShowMembers] = useState(false);
	const [isGroupAdmin, setIsGroupAdmin] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [groupName, setGroupName] = useState("");
	const [deleteInput, setDeleteInput] = useState("");
	const noteIdsRef = useRef(new Set());

	const socketRef = useRef(null);

	const messagesContainerRef = useRef(null);

	useEffect(() => {
		const fetchCurrentUser = async () => {
			try {
				const response = await axios.get("https://www.getawai.online/profile", {
					withCredentials: true,
				});
				setCurrentUser(response.data.username);
			} catch (error) {
				console.error("Failed to fetch current user:", error);
			}
		};

		const fetchNotes = async () => {
			try {
				const response = await axios.get(
					`https://www.getawai.online/get_notes/${groupId}`,
					{
						withCredentials: true,
					}
				);
				const notesFromServer = response.data.notes || [];
				console.log(
					`Notes from server: ${JSON.stringify(notesFromServer, null, 2)}`
				);

				setNotes(notesFromServer);
				noteIdsRef.current = new Set(notesFromServer.map((note) => note._id));
			} catch (error) {
				console.error("Failed to fetch notes:", error);
			}
		};

		const fetchGroupDetails = async () => {
			try {
				const response = await axios.get(
					`https://www.getawai.online/get_groups`,
					{
						withCredentials: true,
					}
				);
				const group = response.data.groups.find((g) => g.id === groupId);
				if (group) {
					setMembers(group.members || []);
					setAdmins(group.admins || []);
					setGroupName(group.name);

					const currentUserResponse = await axios.get(
						"https://www.getawai.online/profile",
						{
							withCredentials: true,
						}
					);
					const currentUsername = currentUserResponse.data.username;
					setCurrentUser(currentUsername);

					if (
						group.admins.some((admin) => admin.username === currentUsername)
					) {
						setIsGroupAdmin(true);
					}
				}
			} catch (error) {
				console.error("Failed to fetch group details:", error);
			}
		};

		fetchCurrentUser();
		fetchNotes();
		fetchGroupDetails();

		// Initialize socket connection
		socketRef.current = io("https://www.getawai.online", {
			withCredentials: true,
			transports: ["websocket"], // Prioritize WebSocket
		});

		socketRef.current.on("connect", () => {
			console.log("Connected to socket server:", socketRef.current.id);
			socketRef.current.emit("join", { group_id: groupId });
		});

		socketRef.current.on("disconnect", (reason) => {
			console.log(`Disconnected from socket server: ${reason}`);
		});

		socketRef.current.on("new_note", (note) => {
			console.log("New note received:", note);
			console.log("Received timestamp:", note.timestamp); 
			if (note.group_id === groupId) {
				console.log(`Note belongs to current group (${groupId})`);
				if (!noteIdsRef.current.has(note._id)) {
					console.log(`Adding new note with ID: ${note._id}`);
					setNotes((prevNotes) => [...prevNotes, note]);
					noteIdsRef.current.add(note._id);
				}
			} else {
				console.log(`Note does not belong to current group (${groupId})`);
			}
		});

		socketRef.current.on("error", (error) => {
			console.error("Socket error:", error);
			toast.error(error.message || "An error occurred with the socket.");
		});

		// Optional: Test Socket Connection
		socketRef.current.emit("test_event", { test: "data" });

		socketRef.current.on("test_response", (data) => {
			console.log("Test response received:", data);
		});

		return () => {
			if (socketRef.current) {
				socketRef.current.emit("leave", { group_id: groupId });
				socketRef.current.off("new_note");
				socketRef.current.off("connect");
				socketRef.current.off("disconnect");
				socketRef.current.off("error");
				socketRef.current.off("test_response");
				socketRef.current.disconnect();
			}
		};
	}, [groupId]);

	const formatDate = (timestamp) => {
		const now = moment();
		const noteDate = moment(timestamp).local(); // Convert UTC to local time
		if (now.isSame(noteDate, "day")) {
			return `Today at ${noteDate.format("h:mm a")}`;
		} else if (now.clone().subtract(1, "days").isSame(noteDate, "day")) {
			return `Yesterday at ${noteDate.format("h:mm a")}`;
		} else if (now.diff(noteDate, "days") <= 2) {
			const daysAgo = now.diff(noteDate, "days");
			return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago at ${noteDate.format(
				"h:mm a"
			)}`;
		} else {
			return noteDate.format("MMMM Do YYYY, h:mm a");
		}
	};

	const promoteToAdmin = async (memberId) => {
		try {
			await axios.post(
				"https://www.getawai.online/promote_to_admin",
				{ group_id: groupId, member_id: memberId },
				{ withCredentials: true }
			);
			setAdmins([...admins, members.find((member) => member._id === memberId)]);
			toast.success("Member promoted to admin successfully");
		} catch (error) {
			console.error("Failed to promote member to admin:", error);
			toast.error("Failed to promote member to admin");
		}
	};

	const removeMember = async (memberId) => {
		try {
			await axios.post(
				"https://www.getawai.online/remove_member",
				{ group_id: groupId, member_id: memberId },
				{ withCredentials: true }
			);
			setMembers(members.filter((member) => member._id !== memberId));
			toast.success("Member removed successfully");
		} catch (error) {
			console.error("Failed to remove member:", error);
			toast.error("Failed to remove member");
		}
	};

	const addNote = async (event) => {
		event.preventDefault();

		if (isSubmitting) return;

		setIsSubmitting(true);

		if (!currentUser || !newNote.trim()) {
			setIsSubmitting(false);
			toast.warning("Note content cannot be empty.");
			return;
		}

		try {
			const response = await axios.post(
				"https://www.getawai.online/create_note",
				{ content: newNote, group_id: groupId },
				{ withCredentials: true }
			);
			console.log("Note created:", response.data.note);
			// toast.success("Note added successfully.");
		} catch (error) {
			console.error("Failed to add note via API", error);
			toast.error("Failed to add note.");
		} finally {
			setIsSubmitting(false);
		}

		setNewNote("");
	};

	const leaveGroup = async () => {
		try {
			await axios.post(
				"https://www.getawai.online/leave_group",
				{ group_id: groupId },
				{ withCredentials: true }
			);
			toast.info(`You left ${groupName} group successfully.`);
			setTimeout(() => {
				navigate("/group-management");
			}, 2000); // Delay (2 seconds)
		} catch (error) {
			console.error("Failed to leave group:", error);
			toast.error("Failed to leave group:", error);
		}
	};

	const scrollToBottom = () => {
		messagesContainerRef.current?.scrollTo({
			top: messagesContainerRef.current.scrollHeight,
			behavior: "smooth",
		});
	};

	useEffect(() => {
		scrollToBottom();
	}, [notes]);

	const renderNotes = () => {
		return notes.map((note) => (
			<div
				key={note._id} // Use unique note ID instead of index
				className="mb-2 p-2 bg-gray-200 dark:bg-gray-800 rounded-lg w-3/4"
				style={{
					alignSelf: note.author === currentUser ? "flex-end" : "flex-start",
					backgroundColor: note.author === currentUser ? "#4ADE80" : "#E5E7EB",
				}}
			>
				<p className="text-black dark:text-black">{note.content}</p>
				<p className="text-sm text-gray-500 dark:text-black">
					- {note.author} at {formatDate(note.timestamp)}
				</p>
			</div>
		));
	};

	const deleteGroup = async () => {
		if (deleteInput !== groupName) {
			toast.error("Group name does not match");
			return;
		}

		try {
			await axios.post(
				"https://www.getawai.online/delete_group",
				{ group_id: groupId },
				{ withCredentials: true }
			);
			toast.success("Group deleted successfully");
			setTimeout(() => {
				navigate("/group-management");
			}, 2000);
		} catch (error) {
			console.error("Failed to delete group:", error);
			toast.error("Failed to delete group:", error);
		}
	};

	const toggleMembers = () => {
		setShowMembers(!showMembers);
	};

	const renderMembers = () => {
		if (showMembers) {
			return (
				<div className="mt-4 p-4 border border-gray-300 rounded">
					<h2 className="text-xl font-semibold mb-2">Group Members</h2>
					<ul>
						{members.map((member, index) => (
							<li
								key={index}
								className="mb-1 flex justify-between items-center"
							>
								<div>
									{member.username}{" "}
									{admins.some((admin) => admin._id === member._id)
										? "(Admin)"
										: "(Member)"}
								</div>
								<div>
									{isGroupAdmin &&
										currentUser !== member.username &&
										!admins.some((admin) => admin._id === member._id) && (
											<>
												<button
													onClick={() => promoteToAdmin(member._id)}
													className="bg-yellow-500 text-white p-1 rounded mr-2"
												>
													Promote to Admin
												</button>
												<button
													onClick={() => removeMember(member._id)}
													className="bg-red-500 text-white p-1 rounded"
												>
													Remove
												</button>
											</>
										)}
								</div>
							</li>
						))}
					</ul>
				</div>
			);
		}
		return null;
	};

	const renderDeleteModal = () => {
		if (!showDeleteModal) return null;

		return (
			<div className="fixed inset-0 flex items-center bg-slate-50 justify-center bg-opacity-50 rounded">
				<div className="bg-white dark:bg-black p-4 rounded-2xl shadow-lg">
					<h2 className="text-xl font-semibold mb-4 text-black dark:text-white">
						Confirm Delete Group
					</h2>
					<p className="text-black dark:text-white">
						Type the group name to confirm deletion:
					</p>
					<input
						type="text"
						value={deleteInput}
						onChange={(e) => setDeleteInput(e.target.value)}
						className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded mb-2 text-black dark:text-white bg-white dark:bg-black"
					/>
					<div className="flex justify-end">
						<button
							onClick={() => setShowDeleteModal(false)}
							className="bg-gray-500 text-white p-2 rounded mr-2"
						>
							Cancel
						</button>
						<button
							onClick={deleteGroup}
							className="bg-red-500 text-white p-2 rounded"
						>
							Delete Group
						</button>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="flex flex-col h-screen bg-white dark:bg-black text-black dark:text-white">
			<div className="flex justify-between items-center p-4 bg-gray-100 dark:bg-gray-800">
				<h1 className="text-2xl font-bold">Group Chat: {groupName}</h1>

				{isGroupAdmin && (
					<button
						onClick={() => setShowDeleteModal(true)}
						className="bg-red-500 text-white p-2 rounded mr-2"
					>
						Delete Group
					</button>
				)}
				{!admins.some((admin) => admin.username === currentUser) && (
					<button
						onClick={leaveGroup}
						className="bg-red-500 text-white p-2 rounded"
					>
						Leave Group
					</button>
				)}
			</div>
			<div className="p-4 bg-gray-100 dark:bg-gray-800 flex justify-between">
				<button
					onClick={toggleMembers}
					className="bg-green-500 text-white p-2 rounded"
				>
					{showMembers ? "Hide Members" : "Show Members"}
				</button>
			</div>
			{renderMembers()}
			<div
				ref={messagesContainerRef}
				className="flex-1 p-4 overflow-y-auto bg-gray-100 dark:bg-gray-900 space-y-4 messages-container"
			>
				{renderNotes()}
			</div>
			<div className="p-4 bg-gray-100 dark:bg-gray-800">
				<form onSubmit={addNote} className="flex space-x-2">
					<Textarea
						placeholder="Write a note..."
						value={newNote}
						onChange={(e) => setNewNote(e.target.value)}
						className="flex-1 p-2 border border-gray-300 dark:border-gray-700 rounded text-black dark:text-white bg-white dark:bg-black"
					/>
					<button type="submit" className="bg-blue-500 text-white p-2 rounded">
						Send
					</button>
				</form>
			</div>
			{renderDeleteModal()}
		</div>
	);
};

export default GroupChat;
