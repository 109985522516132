import axios from "axios";
import "./NewsletterSignup.css";
import React, { useState } from "react";
import { toast } from "react-toastify";

const NewsletterSignup = () => {
	const [email, setEmail] = useState("");

	const handleSubscribe = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(
				"https://www.getawai.online/subscribe",
				{
					withCredentials: true,
					email,
				}
			);
			console.log("subscribe response ");
			console.log(response);
			toast.success(response.data.message);
			setEmail("");
		} catch (error) {
			toast.error(
				error.response.data.message || "Failed to subscribe. Please try again."
			);
		}
	};

	return (
		<section className="newsletter-signup bg-gray-50 dark:bg-black text-gray-800 dark:text-gray-200 p-10 mt-10 rounded-md transition-colors duration-300">
			<h2>Stay Updated</h2>
			<p>Subscribe to our newsletter and never miss an update!</p>
			<form onSubmit={handleSubscribe} className="newsletter-form">
				<input
					type="email"
					placeholder="Enter your email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
					className="newsletter-input bg-gray-50 dark:bg-black text-gray-800 dark:text-gray-200 px-4 py-2 rounded-md w-full md:w-auto"
				/>
				<br></br>
				<button
					type="submit"
					className="subscribe-button bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-md transition-colors duration-300"
				>
					Subscribe
				</button>
			</form>
		</section>
	);
};

export default NewsletterSignup;
