import { Input } from "../ui/input";
import axios from "axios";
import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useUser } from "src/UserContext";

const ShareModal = ({
	itineraryId,
	itineraryData,
	sharerEmail,
	isOpen,
	onClose,
}) => {
	const [recipientEmail, setRecipientEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const { currentUser } = useUser();

	const handleShare = async () => {
		console.log("itinerary data = " + itineraryData);
		setLoading(true);
		try {
			console.log("Sending request to share itinerary...");
			const response = await axios.post(
				"https://www.getawai.online/share-itinerary",
				{
					email: recipientEmail,
					itineraryId: itineraryId,
					itineraryData: itineraryData,
					sharerEmail: sharerEmail,
				},
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response.data.message) {
				toast.success("Invitation sent successfully!");
				setTimeout(() => {
					onClose();
				}, 2000);
			}
			setLoading(false);
		} catch (error) {
			console.error("Error sharing itinerary", error);
			toast.error("Failed to send the invitation.");
			setLoading(false);
		}
	};

	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={onClose}
				contentLabel="Share Itinerary Modal"
				className="modal-content"
				style={{
					content: {
						backgroundColor: "transparent",
						padding: "20px",
						borderRadius: "10px",
						border: "1px solid #ccc",
						maxWidth: "500px",
						margin: "auto",
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					},
					overlay: {
						backgroundColor: "rgba(0, 0, 0, 0.75)",
					},
				}}
			>
				<div className="p-6 bg-white dark:bg-black text-black dark:text-white rounded-lg shadow-lg w-full max-w-md mx-auto border border-gray-300 dark:border-gray-700">
					<h2 className="text-2xl font-bold mb-4 text-center">
						Share Itinerary
					</h2>
					<Input
						type="email"
						value={recipientEmail}
						onChange={(e) => setRecipientEmail(e.target.value)}
						placeholder="Enter friend's email"
						className="w-full p-2 mb-4 bg-white dark:bg-black border border-black dark:border-white text-black dark:text-white rounded"
					/>
					<button
						onClick={handleShare}
						className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition-all"
					>
						{loading ? "Sending..." : "Send Invitation"}
					</button>
					<button
						onClick={onClose}
						className="w-full mt-4 bg-gray-300 dark:bg-gray-700 text-black dark:text-white py-2 rounded hover:bg-gray-400 dark:hover:bg-gray-600 transition-all"
					>
						Close
					</button>
				</div>
			</Modal>
		</>
	);
};

export default ShareModal;
