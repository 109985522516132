import mapboxgl from "mapbox-gl";
import React, { useRef, useEffect, useState, useCallback } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { Skeleton } from "../ui/skeleton";
import pointsOfInterest from "./pointsOfInterest.json";
import mbxDirections from "@mapbox/mapbox-sdk/services/directions";

const MapComponent = ({ userLocation, locations = [], markerType }) => {
	const mapContainerRef = useRef(null);
	const mapboxAccessToken = process.env.REACT_APP_MAPBOX_TOKEN;
	const popupRef = useRef(null);
	const [isMapLoaded, setIsMapLoaded] = useState(false);
	const mapRef = useRef(null);
	// Set the Mapbox access token globally
	mapboxgl.accessToken = mapboxAccessToken;

	const directionsClient = mbxDirections({ accessToken: mapboxAccessToken });

	const addMarkers = useCallback(
		(map) => {
			// Ensure that the map is loaded before adding markers
			if (!isMapLoaded || !map) return;

			// Check if locations is an array
			if (!Array.isArray(locations)) {
				console.error("Locations is not an array:", locations);
				return; // Exit if locations is not an array
			}

			// Add a marker for each location
			locations.forEach(({ coordinates, name }, index) => {
				const [lng, lat] = coordinates; // Extract lng and lat
				new mapboxgl.Marker()
					.setLngLat([lng, lat])
					.setPopup(new mapboxgl.Popup().setText(name)) // Use the name for the popup
					.addTo(map);
			});
		},
		[isMapLoaded, locations]
	); // Only change when map is loaded or locations change

	// Add markers only after the map is loaded and the locations change
	useEffect(() => {
		if (isMapLoaded) {
			addMarkers(); // Add markers once the map is fully loaded
		}
	}, [isMapLoaded, addMarkers]);

	useEffect(() => {
		if (!userLocation) return;

		const getMapStyle = () => {
			return document.documentElement.classList.contains("dark")
				? "mapbox://styles/mapbox/dark-v10"
				: "mapbox://styles/mapbox/streets-v11";
		};

		// Initialize the map
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: getMapStyle(), // Set initial style based on dark mode
			center: [userLocation.lng, userLocation.lat],
			zoom: 8,
		});

		new mapboxgl.Marker()
			.setLngLat([userLocation.lng, userLocation.lat])
			.addTo(map);

		const preserveLayersAndSources = () => {
			const layers = map.getStyle().layers;
			const sources = map.getStyle().sources;

			map.setStyle(getMapStyle());

			map.once("styledata", () => {
				// Re-add all sources
				for (const sourceId in sources) {
					if (!map.getSource(sourceId)) {
						map.addSource(sourceId, sources[sourceId]);
					}
				}

				// Re-add all layers
				for (const layer of layers) {
					if (!map.getLayer(layer.id)) {
						map.addLayer(layer);
					}
				}
			});
		};

		// Listen for dark mode changes
		const observer = new MutationObserver(() => {
			preserveLayersAndSources(); // Preserve layers and sources on style change
		});

		observer.observe(document.documentElement, {
			attributes: true,
			attributeFilter: ["class"],
		});

		map.on("load", async () => {
			const icons = [
				{ name: "acropolis", url: "/icons/acropolis.png" },
				{ name: "alhambra", url: "/icons/alhambra.png" },
				{ name: "amsterdam-canals", url: "/icons/amsterdam-canals.png" },
				{ name: "atomium", url: "/icons/atomium.png" },
				{ name: "big-ben", url: "/icons/big-ben.png" },
				{ name: "brandenburg-gate", url: "/icons/brandenburg-gate.png" },
				{ name: "british-museum", url: "/icons/british-museum.png" },
			];

			setIsMapLoaded(true);

			if (markerType === "user") {
				const userMarkerElement = document.createElement("div");
				userMarkerElement.className = "user-marker"; // Custom styles if needed

				new mapboxgl.Marker(userMarkerElement)
					.setLngLat([userLocation.lng, userLocation.lat])
					.setPopup(
						new mapboxgl.Popup().setHTML(
							`<div style="color: black;">You are here!</div>`
						)
					)
					.addTo(map);
			} else {
				new mapboxgl.Marker()
					.setLngLat([userLocation.lng, userLocation.lat])
					.setPopup(
						new mapboxgl.Popup().setHTML(
							`<div style="color: black;">You are visiting here!</div>`
						)
					)
					.addTo(map);
			}

			// Helper function to normalize image sizes
			const normalizeIconSize = (img, maxDimension = 50) => {
				const width = img.width;
				const height = img.height;
				const maxSize = Math.max(width, height);
				const scale = maxDimension / maxSize;
				return { width: width * scale, height: height * scale };
			};

			// Load all icons dynamically
			icons.forEach((icon) => {
				const img = new Image();
				img.src = icon.url;
				img.onload = () => {
					const { width, height } = normalizeIconSize(img);
					const canvas = document.createElement("canvas");
					canvas.width = width;
					canvas.height = height;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, width, height);
					map.loadImage(canvas.toDataURL(), (error, image) => {
						if (error) {
							console.error(`Error loading image ${icon.url}:`, error);
							return;
						}
						map.addImage(icon.name, image);
					});
				};
			});

			map.addSource("places", {
				type: "geojson",
				data: pointsOfInterest, // Your GeoJSON data containing points of interest
			});

			map.addLayer({
				id: "unclustered-point",
				type: "symbol",
				source: "places",
				layout: {
					"icon-image": ["get", "icon"], // Use the icon ID from GeoJSON properties
					"icon-size": 1, // Keep a fixed size since images are normalized
					"text-field": ["get", "name"],
					"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
					"text-offset": [0, 1.25],
					"text-anchor": "top",
				},
			});

			// Add Gas Stations as a Source
			map.addSource("gas-stations", {
				type: "geojson",
				data: `https://api.mapbox.com/geocoding/v5/mapbox.places/gas%20station.json?proximity=${userLocation.lng},${userLocation.lat}&access_token=${mapboxAccessToken}`,
			});

			map.addLayer({
				id: "gas-stations-layer",
				type: "symbol",
				source: "gas-stations",
				layout: {
					"icon-image": "fuel-15",
					"icon-size": 1.5,
					"text-field": ["get", "text"],
					"text-offset": [0, 1.25],
					"text-anchor": "top",
				},
			});

			map.on("click", "gas-stations-layer", (e) => {
				if (popupRef.current) {
					popupRef.current.remove();
				}
			});

			map.on("mouseenter", "gas-stations-layer", () => {
				map.getCanvas().style.cursor = "pointer";
			});
			map.on("mouseleave", "gas-stations-layer", () => {
				map.getCanvas().style.cursor = "";
			});

			map.on("click", "unclustered-point", (e) => {
				if (popupRef.current) {
					popupRef.current.remove();
				}

				const coordinates = e.features[0].geometry.coordinates.slice();
				const description = e.features[0].properties.description;

				while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
					coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
				}

				const popup = new mapboxgl.Popup({ closeButton: false })
					.setLngLat(coordinates)
					.setHTML(description)
					.addTo(map);

				popupRef.current = popup;

				setTimeout(() => {
					if (popup === popupRef.current) {
						popup.remove();
						popupRef.current = null;
					}
				}, 5000);
			});

			map.on("mouseenter", "unclustered-point", () => {
				map.getCanvas().style.cursor = "pointer";
			});
			map.on("mouseleave", "unclustered-point", () => {
				map.getCanvas().style.cursor = "";
			});

			// Merge the provided code snippet
			let formattedLocations = (locations || [])
				.filter(
					(location) => Array.isArray(location[1]) && location[1].length === 2
				)
				.map(([_, coordinates]) => coordinates);

			console.log(
				"Formatted coordinates before conditional:",
				formattedLocations
			);

			// Check if there's a need to reformat locations
			if (
				locations.length > 0 &&
				Array.isArray(locations[0]) &&
				typeof locations[0] === "number"
			) {
				// Case: Flat array of coordinates (this might be unnecessary if locations are already processed)
				formattedLocations = [];
				for (let i = 0; i < locations.length; i += 2) {
					formattedLocations.push([locations[i + 1], locations[i]]); // [lng, lat]
				}
			} else if (
				locations.length > 0 &&
				Array.isArray(locations[0]) &&
				Array.isArray(locations[0][1])
			) {
				// Case: Locations contain names and coordinates
				formattedLocations = locations.map((location) => location[1]); // assuming location[1] is [lng, lat]
			} else if (
				locations.length > 0 &&
				locations[0].hasOwnProperty("coordinates")
			) {
				// Case: Database fetched itinerary with a `coordinates` field
				formattedLocations = locations.map(({ coordinates }) => coordinates);
			}

			if (formattedLocations.length > 0) {
				formattedLocations.forEach(([lng, lat], index) => {
					new mapboxgl.Marker()
						.setLngLat([lng, lat])
						.setPopup(new mapboxgl.Popup().setText(`Location ${index + 1}`))
						.addTo(map);
				});
			} else {
				console.error("No formatted locations available to add markers.");
			}

			if (formattedLocations.length >= 2) {
				try {
					if (formattedLocations.length < 2) {
						console.log("Not enough waypoints to generate a route.");
						return; // Skip the directions API call if there are fewer than 2 waypoints
					}

					const directionsResponse = await directionsClient
						.getDirections({
							profile: "driving",
							geometries: "geojson",
							waypoints: formattedLocations.map((coord) => ({
								coordinates: coord,
							})),
						})
						.send();

					if (
						directionsResponse &&
						directionsResponse.body &&
						directionsResponse.body.routes &&
						directionsResponse.body.routes.length > 0
					) {
						const route = directionsResponse.body.routes[0].geometry;

						map.addSource("route", {
							type: "geojson",
							data: {
								type: "Feature",
								geometry: route,
							},
						});

						map.addLayer({
							id: "route",
							type: "line",
							source: "route",
							layout: {
								"line-join": "round",
								"line-cap": "round",
							},
							paint: {
								"line-color": "#3887be",
								"line-width": 5,
								"line-opacity": 0.75,
							},
						});

						console.log("Route layer added to map");
					} else {
						console.error("No routes found in the directions response");
					}
				} catch (error) {
					console.error("Error fetching directions:", error);
				}
			}
		});

		return () => {
			map.remove();
			observer.disconnect();
		};
	}, [
		userLocation,
		locations,
		mapboxAccessToken,
		directionsClient,
		markerType,
	]);

	return (
		<>
			{!isMapLoaded && <Skeleton className="map-container w-full h-80" />}
			<div
				ref={mapContainerRef}
				className={`map-container ${!isMapLoaded ? "hidden" : ""}`}
			/>
		</>
	);
};

export default MapComponent;
