// In your front-end code
import React, { useEffect } from "react";
import io from "socket.io-client";

const SOCKET_URL = "https://www.getawai.online";

const SocketTest = () => {
	useEffect(() => {
		const socket = io(SOCKET_URL, {
			withCredentials: true,
			transports: ["websocket", "polling"],
		});

		socket.on("connect", () => {
			console.log("Connected to the server");
		});

		socket.on("my response", (data) => {
			console.log(data);
		});

		socket.on("disconnect", () => {
			console.log("Disconnected from the server");
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	return <div>Socket.IO Test</div>;
};

export default SocketTest;
