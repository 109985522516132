import { Input } from "../ui/input";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUser } from "src/UserContext";

const GroupMemberInvitation = () => {
	const { groupId } = useParams();
	const { currentUser } = useUser();
	const [emailOrUsername, setEmailOrUsername] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [group, setGroup] = useState(null);

	useEffect(() => {
		const fetchGroupDetails = async () => {
			try {
				const response = await axios.get(
					`https://www.getawai.online/get_group?group_id=${groupId}`,
					{ withCredentials: true }
				);
				setGroup(response.data);
			} catch (error) {
				console.error("Failed to fetch group details:", error);
				toast.error("Failed to fetch group details.");
			}
		};

		fetchGroupDetails();
	}, [groupId]);

	const handleInviteMember = async () => {
		if (isSubmitting) return;

		console.log("Current User ID:", currentUser.id);
		console.log(
			"Group Admins:",
			group.group.admins.map((admin) => admin._id)
		);

		if (!group.group.admins.some((admin) => admin._id === currentUser.id)) {
			toast.error(
				"You do not have permission to invite members to this group."
			);
			return;
		}

		setIsSubmitting(true);
		try {
			const response = await axios.post(
				"https://www.getawai.online/invite_to_group",
				{ identifier: emailOrUsername, group_id: groupId },
				{ withCredentials: true }
			);
			setEmailOrUsername("");
			toast.success(response.data.message);
		} catch (error) {
			console.error("Failed to invite member:", error);
			toast.error(error.response?.data?.message || "Failed to invite member");
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div className="min-h-screen bg-slate-50 dark:bg-black text-black dark:text-white">
			<div className="container mx-auto p-4 bg-slate-50 dark:bg-black text-gray-800 dark:text-white transition-colors min-h-screen">
				<h1 className="text-3xl font-bold mb-6 text-black dark:text-white">
					Invite Member to Group
				</h1>
				<div className="mb-4">
					<Input
						type="text"
						placeholder="Email or Username"
						value={emailOrUsername}
						onChange={(e) => setEmailOrUsername(e.target.value)}
						className="w-full md:w-auto bg-white dark:bg-black dark:text-white dark:border-white rounded"
					/>
				</div>
				<button
					onClick={handleInviteMember}
					className="w-full md:w-auto bg-blue-500 hover:bg-blue-600 text-white p-2 rounded"
					disabled={isSubmitting}
				>
					{isSubmitting ? "Inviting..." : "Invite Member"}
				</button>
			</div>
		</div>
	);
};

export default GroupMemberInvitation;
