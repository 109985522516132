import { useUser } from "../../UserContext";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../App.css";
import { Button } from "../ui/button.jsx";

const getSystemTheme = () =>
	window.matchMedia("(prefers-color-scheme: dark)").matches;

const NavBar = () => {
	const { handleLogout } = useUser();
	const navigate = useNavigate();
	const { currentUser, setCurrentUser } = useUser();
	const [showDropdown, setShowDropdown] = useState(false);
	const dropdownTimeoutRef = useRef(null);
	const [isDarkMode, setIsDarkMode] = useState(getSystemTheme());
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

	const toggleTheme = () => {
		const htmlElement = document.documentElement;
		if (htmlElement.classList.contains("dark")) {
			htmlElement.classList.remove("dark");
			setIsDarkMode(false);
			localStorage.setItem("theme", "light"); // Save light theme to localStorage
		} else {
			htmlElement.classList.add("dark");
			setIsDarkMode(true);
			localStorage.setItem("theme", "dark"); // Save dark theme to localStorage
		}
	};

	useEffect(() => {
		const savedTheme = localStorage.getItem("theme");
		if (savedTheme === "dark") {
			document.documentElement.classList.add("dark");
			setIsDarkMode(true); // Set the state to reflect the current theme
		} else {
			document.documentElement.classList.remove("dark");
			setIsDarkMode(false);
		}
	}, []);

	const handleMouseEnter = () => {
		if (dropdownTimeoutRef.current) {
			clearTimeout(dropdownTimeoutRef.current);
		}
		setShowDropdown(true);
	};

	const handleMouseLeave = () => {
		dropdownTimeoutRef.current = setTimeout(() => {
			setShowDropdown(false);
		}, 300); // Delay before closing the dropdown (300ms)
	};

	useEffect(() => {
		return () => {
			if (dropdownTimeoutRef.current) {
				clearTimeout(dropdownTimeoutRef.current);
			}
		};
	}, []);

	const navigateToProfile = () => {
		navigate("/profile");
	};

	const navigateToAdminPage = () => {
		navigate("/adminpage");
	};

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const profileResponse = await axios.get(
					"https://www.getawai.online/profile",
					{
						withCredentials: true,
					}
				);
				if (profileResponse.data) {
					setCurrentUser((prevState) => ({
						...prevState,
						...profileResponse.data,
					}));
				}
			} catch (error) {
				console.error("Failed to fetch user data:", error);
			}
		};

		// fetch only if photo is not present
		if (currentUser && !currentUser.photo_url) {
			fetchUserData();
		}
	}, [currentUser, setCurrentUser]);

	console.log(currentUser);
	return (
		<nav className="bg-white dark:bg-black text-gray-800 dark:text-white p-4 sticky top-0 z-50 shadow-md dark:shadow-[0_10px_15px_-3px_rgba(255,255,255,0.2),0_4px_6px_-2px_rgba(255,255,255,0.1)]">
			<div className="container mx-auto flex justify-between items-center">
				{/* Logo */}
				<div
					className="font-bold text-2xl cursor-pointer"
					onClick={() => navigate("/")}
				>
					GetawAI
				</div>

				{/* Desktop Links */}
				<div className="hidden md:flex items-center space-x-8">
					{currentUser && (
						<a
							href="/home"
							className="text-lg text-gray-800 dark:text-gray-300 hover:text-gray-400"
						>
							Home
						</a>
					)}
					<a
						href="#about"
						className="text-lg text-gray-800 dark:text-gray-300 hover:text-gray-400"
					>
						About
					</a>
					<a
						href="#services"
						className="text-lg text-gray-800 dark:text-gray-300 hover:text-gray-400"
					>
						Services
					</a>
					{currentUser && currentUser.is_admin && (
						<Button
							href="/adminpage"
							className="hover:text-gray-300"
							onClick={navigateToAdminPage}
						>
							Admin Page
						</Button>
					)}
				</div>

				{/* Mobile Hamburger Menu Button */}
				<div className="md:hidden">
					<button
						onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
						className="text-gray-800 dark:text-white"
					>
						<svg
							className="w-6 h-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M4 6h16M4 12h16M4 18h16"
							></path>
						</svg>
					</button>
				</div>

				{/* Dark Mode Toggle Button */}
				<Button
					variant="outline"
					onClick={toggleTheme}
					className="text-sm text-gray-800 dark:text-gray-300 dark:bg-black"
				>
					{isDarkMode ? "Light Mode" : "Dark Mode"}
				</Button>

				{/* Profile Dropdown */}
				{currentUser && (
					<div
						className="relative ml-4 dark:bg-black"
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						<div
							className="flex items-center space-x-2 cursor-pointer dark:bg-black"
							onClick={navigateToProfile}
						>
							{currentUser.photo_url && (
								<img
									src={currentUser.photo_url}
									alt="Profile"
									className="h-8 w-8 rounded-full object-cover"
								/>
							)}
							<span className="text-gray-800 dark:text-gray-300">
								{currentUser.username}
							</span>
						</div>
						{showDropdown && (
							<div className="absolute right-0 mt-2 w-48 bg-gray-500 text-black dark:bg-black rounded-md dark:text-gray-300 shadow-lg py-2 z-50 border border-gray-300 dark:border-gray-600">
								<Link
									to="/profile"
									className="block px-4 py-2 text-white dark:text-gray-300 hover:bg-gray-600"
								>
									My Profile
								</Link>
								<Link
									to="/group-management"
									className="block px-4 py-2 text-white dark:text-gray-300 hover:bg-gray-600"
								>
									Groupchats
								</Link>
								<Link
									to="/itineraries"
									className="block px-4 py-2 text-white dark:text-gray-300 hover:bg-gray-600"
								>
									My Trips
								</Link>
								<button
									onClick={handleLogout}
									className="block w-full text-left px-4 py-2 text-white dark:text-gray-300 hover:bg-gray-600"
								>
									Logout
								</button>
							</div>
						)}
					</div>
				)}
			</div>

			{/* Mobile Menu */}
			{mobileMenuOpen && (
				<div className="md:hidden mt-4 space-y-2">
					{currentUser && (
						<a
							href="/home"
							className="block text-lg text-gray-800 dark:text-gray-300 hover:text-gray-400"
						>
							Home
						</a>
					)}
					<a
						href="#about"
						className="block text-lg text-gray-800 dark:text-gray-300 hover:text-gray-400"
					>
						About
					</a>
					<a
						href="#services"
						className="block text-lg text-gray-800 dark:text-gray-300 hover:text-gray-400"
					>
						Services
					</a>
					{currentUser && currentUser.is_admin && (
						<Button
							className="hover:text-gray-300"
							onClick={navigateToAdminPage}
						>
							Admin Page
						</Button>
					)}
				</div>
			)}
		</nav>
	);
};

export default NavBar;
