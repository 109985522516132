import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./TopDestinations.css";
import { ScrollArea } from "../ui/scroll-area";
import { Skeleton } from "../ui/skeleton";

const TopDestinations = ({ searchQuery, showOnlyFavorites = false }) => {
	const [destinations, setDestinations] = useState([]);
	const scrollContainerRef = useRef(null);
	const [favorites, setFavorites] = useState([]);
	const clickTimeoutRef = useRef(null);
	const [isMouseInScrollArea, setIsMouseInScrollArea] = useState(false);
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();

	const handleDestinationClick = (destination) => {
		if (clickTimeoutRef.current) {
			clearTimeout(clickTimeoutRef.current);
			clickTimeoutRef.current = null;
		} else {
			clickTimeoutRef.current = setTimeout(() => {
				navigate(`/destination/${destination.place_id}`);
				clickTimeoutRef.current = null;
			}, 250); // Adjust the delay as needed
		}
	};

	const handleDoubleClick = async (destination) => {
		if (clickTimeoutRef.current) {
			clearTimeout(clickTimeoutRef.current);
			clickTimeoutRef.current = null;
		}

		const isFavorite = favorites.includes(destination.place_id);

		try {
			if (isFavorite) {
				await axios.post(
					"https://www.getawai.online/remove_favorite",
					{ place_id: destination.place_id },
					{ withCredentials: true }
				);
				setFavorites(favorites.filter((id) => id !== destination.place_id));
			} else {
				await axios.post(
					"https://www.getawai.online/add_favorite",
					{ place_id: destination.place_id },
					{ withCredentials: true }
				);
				setFavorites([...favorites, destination.place_id]);
			}
		} catch (error) {
			console.error("Failed to update favorite status:", error);
		}
	};

	useEffect(() => {
		const handleWheel = (e) => {
			if (isMouseInScrollArea && scrollContainerRef.current) {
				// Prevent vertical scrolling and only allow horizontal scrolling
				e.preventDefault();
				const scrollSensitivity = 8; // Increase the scroll sensitivity here
				scrollContainerRef.current.scrollLeft += e.deltaY * scrollSensitivity;
			}
		};

		const scrollArea = scrollContainerRef.current;
		if (scrollArea) {
			scrollArea.addEventListener("wheel", handleWheel, { passive: false });
		}

		return () => {
			if (scrollArea) {
				scrollArea.removeEventListener("wheel", handleWheel);
			}
		};
	}, [isMouseInScrollArea]);

	useEffect(() => {
		const fetchFavorites = async () => {
			try {
				const response = await axios.get(
					"https://www.getawai.online/get_favorites",
					{ withCredentials: true }
				);
				setFavorites(response.data.favorites);
			} catch (error) {
				console.error("Failed to fetch favorites:", error);
			}
		};

		const fetchDestinations = async () => {
			const userQuery = encodeURIComponent(
				searchQuery || "roadtrips in europe"
			);
			try {
				const response = await axios.get(
					`https://www.getawai.online/get_top_destinations?query=${userQuery}`,
					{ withCredentials: true }
				);
				setDestinations(response.data);
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch top destinations:", error);
				setLoading(false);
			}
		};

		fetchFavorites();
		fetchDestinations();
	}, [searchQuery]);

	const displayedDestinations = showOnlyFavorites
		? destinations.filter((destination) =>
				favorites.includes(destination.place_id)
		  )
		: destinations;

	return (
		<div className="w-full bg-white dark:bg-black">
			<div className="top-destinations">
				<h2 className="text-xl font-semibold mb-4">
					{showOnlyFavorites
						? "Your Favorite Destinations"
						: "Top Destinations"}
				</h2>
				<ScrollArea
					className="w-full h-64 no-scrollbar"
					onMouseEnter={() => setIsMouseInScrollArea(true)}
					onMouseLeave={() => setIsMouseInScrollArea(false)}
				>
					<div
						className="destinations-scroll-container flex items-center space-x-4"
						ref={scrollContainerRef}
						style={{
							overflowX: "auto",
							whiteSpace: "nowrap",
						}}
					>
						{loading
							? // Skeleton for loading state
							  [...Array(12)].map((_, index) => (
									<Skeleton key={index} className="w-48 h-60 rounded-lg" />
							  ))
							: displayedDestinations.map((destination, index) => (
									<div
										key={index}
										className={`destination-card border border-gray-300 dark:border-gray-700 rounded-lg p-2 ${
											favorites.includes(destination.place_id) ? "liked" : ""
										} cursor-pointer`}
										onClick={() => handleDestinationClick(destination)}
										onDoubleClick={() => handleDoubleClick(destination)}
									>
										<img
											src={destination.photo_url}
											alt={destination.name}
											className="destination-image w-full h-40 object-cover rounded-lg"
										/>
										<div
											className="destination-name text-center mt-2 text-black dark:text-white 
									whitespace-nowrap overflow-hidden text-ellipsis"
										>
											{destination.name}
										</div>
										{favorites.includes(destination.place_id) && (
											<div className="favorite-icon text-red-500 text-center mt-1">
												❤️
											</div>
										)}
									</div>
							  ))}
					</div>
				</ScrollArea>
			</div>
		</div>
	);
};

export default TopDestinations;
