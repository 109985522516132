import { useUser } from "../../UserContext";
import MapComponent from "../MapComponent/MapComponent";
import OTPVerificationModal from "../OTPVerificationModal/OTPVerificationModal.jsx";
import ShareModal from "../ShareModal/ShareModal";
import WazeModal from "../WazeModal/WazeModal";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from "../shadcn/components/carousel";
import { Card, CardContent } from "../ui/card";
import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ItineraryPage = () => {
	const location = useLocation();
	const [userLocation, setUserLocation] = useState(null);
	const { currentUser } = useUser();
	const { id } = useParams();
	const [itineraryData, setItineraryData] = useState(null);
	const [locations, setLocations] = useState([]);
	const [numPeople, setNumPeople] = useState(1);
	const [isFetched, setIsFetched] = useState(false); //from database or not
	const [isWazeModalOpen, setIsWazeModalOpen] = useState(false);
	const [wazeLinks, setWazeLinks] = useState([]);
	const [isSaved, setIsSaved] = useState(false);
	const [destinationTitle] = useState(location.state?.destinationTitle || "");
	//sharing
	const [isShareModalOpen, setIsShareModalOpen] = useState(false);
	const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
	const { token } = useParams();

	const MemoizedMapComponent = React.memo(MapComponent);

	useEffect(() => {
		const hasShownToast = sessionStorage.getItem("hasShownSwipeToast");

		if (!hasShownToast) {
			toast.info("You can swipe the cards to explore more!", {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "light", // or "dark"
			});
			sessionStorage.setItem("hasShownSwipeToast", "true");
		}
	}, []);

	useEffect(() => {
		if (navigator.geolocation && !token) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					setUserLocation({
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					});
				},
				(error) => {
					console.error("Error fetching geolocation: ", error);
				}
			);
		} else if (locations.length > 0) {
			setUserLocation({
				lat: locations[0].coordinates[1], // Latitude of the first location
				lng: locations[0].coordinates[0], // Longitude of the first location
			});
		}
	}, [locations, token]);

	const memoizedLocations = useMemo(() => locations, [locations]);

	// Function to save state to sessionStorage
	const saveToSessionStorage = (key, value) => {
		sessionStorage.setItem(key, JSON.stringify(value));
	};

	// Function to load state from sessionStorage
	const loadFromSessionStorage = (key) => {
		const storedData = sessionStorage.getItem(key);
		return storedData ? JSON.parse(storedData) : null;
	};

	const handleItineraryVerified = (itinerary) => {
		setItineraryData(itinerary); // Update the itinerary data
		setLocations(itinerary.locations || []);
		setNumPeople(itinerary.group_size || 1);
		setIsFetched(true);
	};

	useEffect(() => {
		// Automatically open the OTP modal when the page is loaded
		if (token) {
			setIsOtpModalOpen(true); // Open OTP modal if token exists
		}
	}, [token]);

	const handleOtpModalClose = () => {
		setIsOtpModalOpen(false); // Close the OTP modal after successful verification
	};

	useEffect(() => {
		if (!id && !location.state?.itineraryData) {
			console.error("Neither itinerary ID nor state data available.");
			return; // Exit if neither ID nor state data is available
		}
		// Check if the current ID matches the stored ID in sessionStorage
		const storedTripId = sessionStorage.getItem("tripId");

		if (storedTripId && storedTripId !== id) {
			// If a new trip starts, clear the sessionStorage
			sessionStorage.removeItem("itineraryData");
			sessionStorage.removeItem("locations");
			sessionStorage.removeItem("numPeople");
		}

		// Save the current trip ID in sessionStorage
		sessionStorage.setItem("tripId", id);

		// Load saved state from sessionStorage
		const savedItineraryData = loadFromSessionStorage("itineraryData");
		const savedLocations = loadFromSessionStorage("locations");
		const savedNumPeople = loadFromSessionStorage("numPeople");

		if (savedItineraryData) {
			setItineraryData(savedItineraryData);
			setLocations(savedLocations || []);
			setNumPeople(savedNumPeople || 1);
			setIsFetched(true);
		} else if (location.state?.itineraryData) {
			// If data is passed via state, use it directly
			const data = location.state.itineraryData;
			console.log(
				"Fetched Itinerary Data FROM STATE: ",
				JSON.stringify(data, null, 2)
			);
			setItineraryData(data.itinerary || data);
			setNumPeople(data.itinerary.group_size);
			setLocations(data.locations || []);
			// Save the fetched data to sessionStorage
			saveToSessionStorage("itineraryData", data.itinerary || data);
			saveToSessionStorage("locations", data.locations || []);
			saveToSessionStorage("numPeople", data.itinerary.group_size || 1);
			// console.log("locations======== " + data.locations);
			// console.log("ITINERARY DATAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
			// console.log(data);
		} else {
			// Otherwise, fetch from the backend using the ID
			const fetchItinerary = async () => {
				try {
					const response = await axios.get(
						`https://www.getawai.online/itinerary/${id}`
					);
					const data = response.data;
					console.log(
						"Fetched Itinerary Data FROM DATABASE: ",
						JSON.stringify(data, null, 2)
					);
					setItineraryData(data.itinerary || data);
					const formattedLocs = formatLocations(data.locations || []);
					setLocations(formattedLocs);
					setNumPeople(data.group_size || 1);
					setIsFetched(true);

					// Save the fetched data to sessionStorage
					saveToSessionStorage("itineraryData", data.itinerary || data);
					saveToSessionStorage("locations", formattedLocs);
					saveToSessionStorage("numPeople", data.group_size || 1);
				} catch (error) {
					console.error("Error fetching itinerary:", error);
				}
			};
			fetchItinerary();
		}
	}, [id, location.state]);

	// Function to handle the modification of individual items
	const handleModifyItem = useCallback(
		async (
			section,
			dayIndex,
			itemIndex,
			itemType,
			city,
			name,
			currentPrice
		) => {
			try {
				const response = await axios.post(
					`https://www.getawai.online/modify_item`,
					{
						section,
						dayIndex,
						itemIndex,
						itemType,
						city,
						name,
						currentPrice,
					},
					{
						withCredentials: true,
					}
				);
				const modifiedItem = response.data;

				setItineraryData((prevData) => {
					const updatedDays = [...prevData.days];

					if (section && updatedDays[dayIndex][section]) {
						if (itemType === "breakfast") {
							updatedDays[dayIndex][section][itemType] = {
								name: modifiedItem.name,
								price: modifiedItem.price,
								url: modifiedItem.url,
							};
						} else if (
							itemType === "lunch_options" ||
							itemType === "dinner_options" ||
							itemType === "activities"
						) {
							const updatedSection = Array.isArray(
								updatedDays[dayIndex][section][itemType]
							)
								? [...updatedDays[dayIndex][section][itemType]]
								: [];

							updatedSection[itemIndex] = {
								name: modifiedItem.name,
								price: modifiedItem.price,
								url: modifiedItem.url,
							};

							updatedDays[dayIndex][section][itemType] = updatedSection;
						}
					}

					const updatedData = { ...prevData, days: updatedDays };
					// Save the modified data to sessionStorage
					saveToSessionStorage("itineraryData", updatedData);
					return updatedData;
				});
			} catch (error) {
				console.error("Error modifying item:", error);
			}
		},
		[]
	);

	const handleAddPerson = () => setNumPeople((prev) => prev + 1);
	const handleRemovePerson = () =>
		setNumPeople((prev) => (prev > 1 ? prev - 1 : 1));

	const handleSaveItinerary = async () => {
		if (!currentUser) {
			toast.error("You need to be logged in to save an itinerary.");
			return;
		}

		const apiUrl = "https://www.getawai.online/save-itinerary";

		try {
			const response = await axios.post(apiUrl, {
				userEmail: currentUser.email,
				itineraryData: {
					...itineraryData,
					destinationTitle: destinationTitle, // Ensure destinationTitle is passed
				},
				locations: locations,
			});
			if (response.status === 200) {
				setIsSaved(true);
				toast.success("Itinerary saved successfully.");
			} else {
				toast.error("Failed to save itinerary.");
			}
		} catch (error) {
			console.error("Error saving itinerary:", error);
			toast.error("An error occured while saving itinerary:", error);
		}
	};

	const renderMapComponent = useMemo(() => {
		if (userLocation && locations.length > 0) {
			return (
				<MemoizedMapComponent
					userLocation={userLocation}
					locations={memoizedLocations}
					markerType={"user"}
				/>
			);
		} else {
			return <p>Loading map...</p>;
		}
	}, [userLocation, memoizedLocations, locations.length]);

	const totalFuelCost = itineraryData?.total_fuel_cost || 0;
	const days = itineraryData?.days || [];

	const formatLocations = (locations) => {
		let coordinates = [];

		if (
			locations.length > 0 &&
			typeof locations[0] === "string" &&
			!locations[0].includes(",")
		) {
			// Assume locations are in "city,lat,lng" format
			for (let i = 1; i < locations.length; i += 3) {
				coordinates.push([
					parseFloat(locations[i + 1]),
					parseFloat(locations[i]),
				]);
			}
		} else if (
			locations.length > 0 &&
			Array.isArray(locations[0]) &&
			typeof locations[0][0] === "number"
		) {
			// Case: Fetched Itineraries (flat array of coordinates)
			for (let i = 0; i < locations.length; i += 2) {
				coordinates.push([locations[i + 1], locations[i]]); // Convert to [lng, lat]
			}
		} else if (
			locations.length > 0 &&
			Array.isArray(locations[0]) &&
			Array.isArray(locations[0][1])
		) {
			// Case: Generated Itineraries (with names)
			coordinates = locations.map(([name, [lng, lat]]) => [lng, lat]);
		} else {
			// Default case, assuming correct format
			coordinates = locations;
		}

		return coordinates;
	};

	const formattedLocations = formatLocations(locations);

	const handleOpenInGoogleMaps = () => {
		if (isFetched && itineraryData && itineraryData.locations.length > 1) {
			// Use itineraryData.locations when data is fetched
			const origin = itineraryData.locations[0].coordinates; // The first location as the origin
			const destination =
				itineraryData.locations[itineraryData.locations.length - 1].coordinates; // The last location as the destination

			// All intermediate locations are waypoints (excluding the first and last)
			const waypoints = itineraryData.locations
				.slice(1, -1)
				.map(
					(location) => `${location.coordinates[1]},${location.coordinates[0]}`
				)
				.join("|");

			// Construct the Google Maps URL with origin, destination, and waypoints
			const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin[1]},${origin[0]}&destination=${destination[1]},${destination[0]}&waypoints=${waypoints}`;
			window.open(googleMapsUrl, "_blank");
		} else if (!isFetched && formattedLocations.length > 1) {
			// Use `formattedLocations` if the data is not fetched
			const origin = formattedLocations[0];
			const destination = formattedLocations[formattedLocations.length - 1];
			const waypoints = formattedLocations
				.slice(1, -1)
				.map((coord) => `${coord[1]},${coord[0]}`)
				.join("|");

			// Construct the Google Maps URL with origin, destination, and waypoints
			const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin[1]},${origin[0]}&destination=${destination[1]},${destination[0]}&waypoints=${waypoints}`;
			window.open(googleMapsUrl, "_blank");
		} else {
			// If neither condition is met, log an error
			console.error(
				"Not enough locations to generate a full route in Google Maps."
			);
		}
	};

	const handleOpenInWaze = () => {
		// Make sure itineraryData.locations exists and is an array
		if (
			isFetched &&
			Array.isArray(itineraryData?.locations) &&
			itineraryData.locations.length > 1
		) {
			console.log(
				"Fetched data: Using itineraryData.locations and formattedLocations"
			);

			const wazeLinks = itineraryData.locations.map((location, index) => {
				if (index < itineraryData.locations.length - 1) {
					const currentLocation = itineraryData.locations[index];
					const nextLocation = itineraryData.locations[index + 1];

					if (currentLocation && nextLocation) {
						const { coordinates: currentCoordinates } = currentLocation;
						const { coordinates: nextCoordinates } = nextLocation;

						if (currentCoordinates && nextCoordinates) {
							const wazeUrl = `https://www.waze.com/ul?ll=${nextCoordinates[1]},${nextCoordinates[0]}&navigate=yes&from=${currentCoordinates[1]},${currentCoordinates[0]}`;
							return {
								label: `Day ${index + 1}: From ${currentLocation.name} to ${
									nextLocation.name
								}`,
								url: wazeUrl,
							};
						}
					}
				}
				return null; // Skip if no next location
			});

			const validWazeLinks = wazeLinks.filter((link) => link !== null);
			setWazeLinks(validWazeLinks);
			setIsWazeModalOpen(true);
		} else if (Array.isArray(locations) && locations.length > 1) {
			console.log("Using state locations:", locations);

			const wazeLinks = locations.map((location, index) => {
				if (index < locations.length - 1) {
					const currentName = locations[index][0];
					const nextName = locations[index + 1][0];
					const currentCoordinates = locations[index][1];
					const nextCoordinates = locations[index + 1][1];

					if (currentCoordinates && nextCoordinates) {
						const wazeUrl = `https://www.waze.com/ul?ll=${nextCoordinates[1]},${nextCoordinates[0]}&navigate=yes&from=${currentCoordinates[1]},${currentCoordinates[0]}`;
						return {
							label: `From ${currentName} to ${nextName}`,
							url: wazeUrl,
						};
					}
				}
				return null;
			});

			const validWazeLinks = wazeLinks.filter((link) => link !== null);
			setWazeLinks(validWazeLinks);
			setIsWazeModalOpen(true);
		} else {
			console.error(
				"Not enough locations to generate Waze links or missing itinerary data."
			);
		}
	};
	const handleShare = () => {
		setIsShareModalOpen(true);
	};

	const handleShareModalClose = () => {
		setIsShareModalOpen(false); // Close the modal when needed
	};

	return (
		<div className="w-full min-h-screen bg-white dark:bg-black">
			<div className="container mx-auto p-4 bg-white dark:bg-black text-black dark:text-white">
				{days.length > 0 ? (
					<>
						<div className="w-full max-w-2xl mx-auto mb-4">
							<Card className="bg-gray-100 dark:bg-black text-black dark:text-gray-100 dark:border-white">
								<CardContent className="p-6">
									<h2 className="text-xl font-bold mb-4">
										Fuel Cost Information
									</h2>
									<p className="mb-4">
										Total Fuel Cost: €{totalFuelCost.toFixed(2)}
									</p>
									<div className="flex items-center mb-4">
										<button
											className="px-3 py-1 bg-blue-500 text-white rounded mr-2"
											onClick={handleRemovePerson}
										>
											-
										</button>
										<span className="font-semibold">
											{numPeople > 0 ? numPeople : 1}
										</span>
										<button
											className="px-3 py-1 bg-blue-500 text-white rounded ml-2"
											onClick={handleAddPerson}
										>
											+
										</button>
									</div>
									<p>
										Cost per Person: €
										{numPeople > 0
											? (totalFuelCost / numPeople).toFixed(2)
											: totalFuelCost.toFixed(2)}
									</p>
									<p className="text-center text-red-700">
										Any changes made after saving or sharing will not be
										reflected in the saved itinerary.
									</p>
								</CardContent>
							</Card>
						</div>

						<Carousel className="w-full max-w-2xl mx-auto mt-6">
							<CarouselContent>
								{days.map((dayData, index) => (
									<CarouselItem key={index}>
										<div className="p-1">
											<Card className="bg-gray-100 dark:bg-black text-black dark:text-slate-200 dark:border-white">
												<CardContent className="p-6">
													<h2 className="text-2xl font-bold mb-4 text-center">
														Day {index + 1}:{" "}
														{dayData.from && dayData.to
															? `${dayData.from.trim()} to ${dayData.to.trim()}`
															: `Explore ${
																	dayData.to?.trim() ||
																	dayData.from?.trim() ||
																	"Location"
															  }`}
													</h2>
													<div className="bg-gray-200 dark:bg-black p-4 mt-2 rounded-lg shadow-lg">
														{dayData.from && dayData.to && (
															<>
																<p>
																	<strong>From:</strong> {dayData.from}
																</p>
																<p>
																	<strong>To:</strong> {dayData.to}
																</p>
																<p>
																	<strong>Distance:</strong>{" "}
																	{dayData.distance?.toLocaleString()}
																</p>
																<p>
																	<strong>Drive Time:</strong>{" "}
																	{dayData.drive_time}
																</p>
															</>
														)}
														{dayData.morning && (
															<>
																<h3 className="text-lg font-bold mt-4">
																	Morning
																</h3>
																<p>
																	<strong>Departure Time:</strong>{" "}
																	{dayData.morning?.departure_time || "N/A"}
																</p>
																<p>
																	<strong>Weather Forecast:</strong>{" "}
																	{dayData.morning?.weather_forecast || "N/A"}
																</p>
																<div>
																	<strong>Breakfast at:</strong>{" "}
																	<a
																		href={dayData.morning?.breakfast?.url}
																		target="_blank"
																		rel="noopener noreferrer"
																		className="text-blue-500 underline"
																	>
																		{dayData.morning?.breakfast?.name}
																	</a>{" "}
																	({dayData.morning?.breakfast?.price} euros per
																	person){" "}
																	{!token && (
																		<button
																			className="text-sm text-blue-500 ml-2"
																			onClick={() =>
																				handleModifyItem(
																					"morning",
																					index,
																					0,
																					"breakfast",
																					dayData.to,
																					dayData.morning?.breakfast?.name,
																					dayData.morning?.breakfast?.price
																				)
																			}
																		>
																			Modify
																		</button>
																	)}
																</div>
																<h4 className="font-semibold mt-2">
																	Activities:
																</h4>
																{dayData.morning?.activities &&
																dayData.morning.activities.length > 0 ? (
																	dayData.morning.activities.map(
																		(activity, idx) => (
																			<div key={idx}>
																				<a
																					href={activity.url}
																					target="_blank"
																					rel="noopener noreferrer"
																					className="text-blue-500 underline"
																				>
																					{activity.name}
																				</a>{" "}
																				(
																				{activity.price?.toLocaleString() ||
																					"Free"}{" "}
																				euros per person){" "}
																				{!token && (
																					<button
																						className="text-sm text-blue-500 ml-2"
																						onClick={() =>
																							handleModifyItem(
																								"morning",
																								index,
																								idx,
																								"activities",
																								dayData.to,
																								activity.name,
																								activity.price
																							)
																						}
																					>
																						Modify
																					</button>
																				)}
																			</div>
																		)
																	)
																) : (
																	<p>No morning activities planned.</p>
																)}
															</>
														)}

														{dayData.afternoon && (
															<>
																<h3 className="text-lg font-bold mt-4">
																	Afternoon
																</h3>
																<div>
																	{dayData.afternoon?.arrival_time &&
																		dayData.afternoon?.arrival_time !==
																			"N/A" && (
																			<p>
																				<strong>
																					Arrival time in {dayData.to} at:
																				</strong>{" "}
																				{dayData.afternoon?.arrival_time}
																			</p>
																		)}
																</div>
																{dayData.afternoon?.lunch_options?.length >
																	0 && (
																	<>
																		<h4 className="font-semibold mt-2">
																			Lunch options:
																		</h4>
																		{dayData.afternoon?.lunch_options.map(
																			(lunch, idx) => (
																				<div key={idx}>
																					<a
																						href={lunch.url}
																						target="_blank"
																						rel="noopener noreferrer"
																						className="text-blue-500 underline"
																					>
																						{lunch.name}
																					</a>{" "}
																					({lunch.price} euros per person){" "}
																					{!token && (
																						<button
																							className="text-sm text-blue-500 ml-2"
																							onClick={() =>
																								handleModifyItem(
																									"afternoon",
																									index,
																									idx,
																									"lunch_options",
																									dayData.to,
																									lunch.name,
																									lunch.price
																								)
																							}
																						>
																							Modify
																						</button>
																					)}
																				</div>
																			)
																		)}
																	</>
																)}

																<h4 className="font-semibold mt-2">
																	Activities:
																</h4>
																{dayData.afternoon?.activities.map(
																	(activity, idx) => (
																		<div key={idx}>
																			<a
																				href={activity.url}
																				target="_blank"
																				rel="noopener noreferrer"
																				className="text-blue-500 underline"
																			>
																				{activity.name}
																			</a>{" "}
																			({activity.price} euros per person){" "}
																			{!token && (
																				<button
																					className="text-sm text-blue-500 ml-2"
																					onClick={() =>
																						handleModifyItem(
																							"afternoon",
																							index,
																							idx,
																							"activities",
																							dayData.to,
																							activity.name,
																							activity.price
																						)
																					}
																				>
																					Modify
																				</button>
																			)}
																		</div>
																	)
																)}
															</>
														)}

														{dayData.evening && (
															<>
																<h3 className="text-lg font-bold mt-4">
																	Evening
																</h3>

																<h4 className="font-semibold mt-2">
																	Dinner options:
																</h4>
																{dayData.evening?.dinner_options?.length > 0 ? (
																	dayData.evening.dinner_options.map(
																		(dinner, idx) => (
																			<div key={idx}>
																				<a
																					href={dinner.url}
																					target="_blank"
																					rel="noopener noreferrer"
																					className="text-blue-500 underline"
																				>
																					{dinner.name}
																				</a>{" "}
																				({dinner.price} euros per person){" "}
																				{!token && (
																					<button
																						className="text-sm text-blue-500 ml-2"
																						onClick={() =>
																							handleModifyItem(
																								"evening",
																								index,
																								idx,
																								"dinner_options",
																								dayData.to,
																								dinner.name,
																								dinner.price
																							)
																						}
																					>
																						Modify
																					</button>
																				)}
																			</div>
																		)
																	)
																) : (
																	<p>No dinner options planned.</p> // Add this fallback when no dinner options
																)}

																<h4 className="font-semibold mt-2">
																	Activities:
																</h4>
																{dayData.evening?.activities?.length > 0 ? (
																	dayData.evening.activities.map(
																		(activity, idx) => (
																			<div key={idx}>
																				<a
																					href={activity.url}
																					target="_blank"
																					rel="noopener noreferrer"
																					className="text-blue-500 underline"
																				>
																					{activity.name}
																				</a>{" "}
																				({activity.price} euros per person){" "}
																				{!token && (
																					<button
																						className="text-sm text-blue-500 ml-2"
																						onClick={() =>
																							handleModifyItem(
																								"evening",
																								index,
																								idx,
																								"activities",
																								dayData.to,
																								activity.name,
																								activity.price
																							)
																						}
																					>
																						Modify
																					</button>
																				)}
																			</div>
																		)
																	)
																) : (
																	<p>No activities planned.</p> // Add this fallback when no activities
																)}
															</>
														)}

														{dayData.accommodation &&
															dayData.accommodation.length > 0 && (
																<>
																	<h3 className="text-lg font-bold mt-4">
																		Accommodation
																	</h3>
																	{dayData.accommodation?.map((acc, idx) => (
																		<div key={idx}>
																			<span
																				dangerouslySetInnerHTML={{
																					__html: acc.url,
																				}}
																				className="text-blue-500 underline"
																			/>{" "}
																			- {acc.price} euros per night{" "}
																		</div>
																	))}
																</>
															)}
													</div>
												</CardContent>
											</Card>
										</div>
									</CarouselItem>
								))}
							</CarouselContent>
						</Carousel>

						<div className="itinerary-container mx-auto mt-4">
							<div style={{ transform: "translateX(-10vw)" }}>
								{renderMapComponent}
							</div>

							<div className="w-full max-w-2xl mx-auto mt-4">
								{locations.length > 0 && (
									<div className="flex justify-between space-x-4">
										<button
											onClick={handleOpenInGoogleMaps}
											className="flex-1 px-4 py-2 bg-blue-500 text-white rounded"
										>
											Open in Google Maps
										</button>
										<button
											onClick={handleOpenInWaze}
											className="flex-1 px-4 py-2 bg-green-500 text-white rounded"
										>
											Open in Waze
										</button>
									</div>
								)}
								{!isFetched && (
									<button
										className="w-full mt-4 py-2 bg-green-500 text-white rounded"
										onClick={handleSaveItinerary}
										disabled={isSaved}
										style={{
											cursor: isSaved ? "not-allowed" : "pointer",
											backgroundColor: isSaved ? "grey" : "blue", // Optional: change button color when disabled
										}}
									>
										{isSaved
											? "Already Saved. Consult it at Profile > My Trips"
											: "Save Itinerary"}
									</button>
								)}
								{!token && (
									<button
										className="w-full mt-4 py-2 bg-blue-800 text-white rounded"
										onClick={handleShare}
									>
										Share with friends
									</button>
								)}
								{isShareModalOpen && (
									<ShareModal
										isOpen={isShareModalOpen}
										itineraryId={itineraryData._id}
										itineraryData={itineraryData}
										sharerEmail={currentUser.email}
										onClose={handleShareModalClose} // Pass a function to close the modal
									/>
								)}
							</div>
						</div>
						<WazeModal // Modal component to show Waze links
							isOpen={isWazeModalOpen}
							onClose={() => setIsWazeModalOpen(false)}
							wazeLinks={wazeLinks}
						/>
					</>
				) : (
					<p className="text-center">
						No itinerary data available. Please submit the form to generate an
						itinerary.
					</p>
				)}
				<OTPVerificationModal
					isOpen={isOtpModalOpen}
					token={token} // Pass the token from the URL
					onItineraryVerified={handleItineraryVerified}
					onClose={handleOtpModalClose} // Close modal function
				/>
			</div>
		</div>
	);
};

export default ItineraryPage;
