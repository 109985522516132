import { Button } from "../ui/button.jsx";
import { Card, CardHeader, CardContent, CardFooter } from "../ui/card.jsx";
import { Input } from "../ui/input.jsx";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
	faCheck,
	faTimes,
	faEye,
	faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import "./SignUpPage.css";
import "../../App.css";

function SignupPage() {
	const [formData, setFormData] = useState({
		username: "",
		name: "",
		email: "",
		password: "",
	});
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleGoogleLogin = () => {
		window.location.href = "https://www.getawai.online/auth/google";
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const url = "https://www.getawai.online/register";
		const isValidPassword =
			formData.password.length >= 8 &&
			/[A-Z]/.test(formData.password) &&
			/[0-9]/.test(formData.password);

		if (!isValidPassword) {
			toast.error("Password does not meet the required criteria.");
			return;
		}

		try {
			await axios.post(url, formData, { withCredentials: true });
			toast.success(
				"User registered successfully! You will be redirected to the login page."
			);
			setTimeout(() => navigate("/login"), 2000); // Redirect to login page after 2 seconds
		} catch (error) {
			toast.error("Registration failed. Please try again.");
		}
	};

	const isLengthValid = formData.password.length >= 8;
	const hasUppercase = /[A-Z]/.test(formData.password);
	const hasNumber = /[0-9]/.test(formData.password);

	return (
		<div className="min-h-screen flex flex-col justify-center items-center bg-white dark:bg-black text-black dark:text-white transition-colors duration-300">
			<Card className="w-full max-w-md bg-white dark:bg-black text-black dark:text-white shadow-md rounded-lg transition-colors duration-300">
				<CardHeader className="text-center">
					<h2 className="text-2xl font-bold">Sign Up</h2>
				</CardHeader>
				<CardContent>
					<form onSubmit={handleSubmit} className="space-y-6">
						<Input
							type="text"
							placeholder="Username"
							name="username"
							value={formData.username}
							onChange={handleInputChange}
							required
							className="w-full bg-gray-100 dark:bg-black dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
						/>
						<Input
							type="text"
							placeholder="Name"
							name="name"
							value={formData.name}
							onChange={handleInputChange}
							required
							className="w-full bg-gray-100 dark:bg-black dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
						/>
						<Input
							type="email"
							placeholder="Email"
							name="email"
							value={formData.email}
							onChange={handleInputChange}
							required
							className="w-full bg-gray-100 dark:bg-black dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
						/>
						<div className="relative text-black dark:text-white">
							<Input
								type={showPassword ? "text" : "password"}
								placeholder="Password"
								name="password"
								value={formData.password}
								onChange={handleInputChange}
								required
								className="w-full bg-gray-100 dark:bg-black dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 pr-10"
							/>
							<button
								type="button"
								onClick={() => setShowPassword(!showPassword)}
								className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 dark:text-gray-400"
								aria-label={showPassword ? "Hide password" : "Show password"}
							>
								<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
							</button>
						</div>
						<div className="mt-2 text-black dark:text-white">
							<p className="text-sm mb-1">Password must contain:</p>
							<ul className="list-none pl-0">
								<li className="flex items-center text-sm">
									<FontAwesomeIcon
										icon={isLengthValid ? faCheck : faTimes}
										className={`mr-2 ${
											isLengthValid ? "text-green-500" : "text-red-500"
										}`}
									/>
									At least 8 characters
								</li>
								<li className="flex items-center text-sm">
									<FontAwesomeIcon
										icon={hasUppercase ? faCheck : faTimes}
										className={`mr-2 ${
											hasUppercase ? "text-green-500" : "text-red-500"
										}`}
									/>
									At least one uppercase letter
								</li>
								<li className="flex items-center text-sm">
									<FontAwesomeIcon
										icon={hasNumber ? faCheck : faTimes}
										className={`mr-2 ${
											hasNumber ? "text-green-500" : "text-red-500"
										}`}
									/>
									At least one number
								</li>
							</ul>
						</div>
						<Button
							type="submit"
							className="w-full bg-blue-500 hover:bg-blue-600 text-white rounded-md py-2 transition-all duration-200"
						>
							Sign Up
						</Button>
					</form>

					<Button
						type="button"
						onClick={handleGoogleLogin}
						className="w-full mt-4 bg-red-600 dark:bg-red-600 hover:bg-red-700 dark:text-white text-white rounded-md py-2 transition-all duration-200 flex items-center justify-center"
					>
						<FontAwesomeIcon icon={faGoogle} className="mr-2" />
						Continue with Google
					</Button>
				</CardContent>

				<CardFooter className="flex justify-between mt-4">
					<Link
						to="/login"
						className="text-sm text-blue-600 dark:text-blue-400 hover:underline hover:text-blue-700 dark:hover:text-blue-500 transition-all duration-200"
					>
						Already have an account? Log in
					</Link>
				</CardFooter>
			</Card>
		</div>
	);
}

export default SignupPage;
