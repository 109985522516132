import { useUser } from "../../UserContext";
import {
	AlertDialog,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogFooter,
	AlertDialogTitle,
	AlertDialogDescription,
	AlertDialogCancel,
	AlertDialogAction,
} from "../ui/alert-dialog";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import axios from "axios";
import React, { useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Import your notification component

// You can use a modal library or create your own
Modal.setAppElement("#root"); // Required for accessibility

const DeleteAccount = () => {
	const { currentUser, handleLogout } = useUser();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const navigate = useNavigate();

	const handleDeleteAccount = async () => {
		if (!currentUser || !currentUser.email) {
			toast.error("No user is logged in.");
			return;
		}

		if (inputValue !== "DELETE") {
			setError("Please type DELETE to confirm.");
			return;
		}

		setIsLoading(true); // Show loading state
		setError(""); // Clear any previous errors

		try {
			const response = await axios.delete(
				"https://www.getawai.online/delete-account",
				{
					withCredentials: true,
				}
			);

			setTimeout(() => {
				if (response.status === 200) {
					toast.success("Account deleted successfully.");
					handleLogout();
					navigate("/");
				} else {
					toast.error("Failed to delete account.");
				}

				setIsLoading(false); // Stop loading state after 3 seconds
			}, 3000);
		} catch (error) {
			console.error("Error deleting account:", error);
			toast.error(
				"An error occurred while trying to delete the account. Please contact our client service!"
			);

			setTimeout(() => {
				setIsLoading(false);
			}, 3000);
		}
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div>
			<Button className="bg-red-500 text-white p-2 rounded" onClick={openModal}>
				Delete Account
			</Button>

			<AlertDialog open={isModalOpen} onOpenChange={setIsModalOpen}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle className="text-red-600 text-2xl">
							Are you sure you want to delete your account?
						</AlertDialogTitle>
						<AlertDialogDescription className="mb-4">
							This action cannot be undone. Please type <strong>DELETE</strong>{" "}
							in the box below to confirm.
						</AlertDialogDescription>
					</AlertDialogHeader>

					<Input
						type="text"
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						placeholder="Type DELETE to confirm"
						className="mb-4"
					/>

					{error && <p className="text-red-500 text-center mb-4">{error}</p>}

					{isLoading ? (
						<div className="flex justify-center items-center">
							<div className="loader"></div>
							<p className="text-gray-500 ml-4">Deleting all your data...</p>
						</div>
					) : (
						<AlertDialogFooter className="flex justify-between">
							<AlertDialogAction
								onClick={handleDeleteAccount}
								className="bg-red-600 text-white px-4 py-2 rounded"
							>
								Confirm Delete
							</AlertDialogAction>
							<AlertDialogCancel
								onClick={closeModal}
								className="bg-gray-300 dark:bg-gray text-black px-4 py-2 rounded"
							>
								Cancel
							</AlertDialogCancel>
						</AlertDialogFooter>
					)}
				</AlertDialogContent>
			</AlertDialog>
		</div>
	);
};

export default DeleteAccount;
