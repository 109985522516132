import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./DestinationDetails.css";
import MapComponent from "../MapComponent/MapComponent";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from "../shadcn/components/carousel";
import { Button } from "../ui/button";
import { Card, CardContent } from "../ui/card";
import { Skeleton } from "../ui/skeleton";
// import { useUser } from "../../UserContext";
import { format } from "date-fns";

const DestinationDetails = () => {
	const { placeId } = useParams();
	// const { currentUser } = useUser(); // Get current user from context
	const [placeDetails, setPlaceDetails] = useState(null);
	const [destinationLocation, setDestinationLocation] = useState(null); // State for destination coordinates
	const [weatherData, setWeatherData] = useState(null); // State for weather data
	const [touristInfo, setTouristInfo] = useState("");

	const navigate = useNavigate();

	useEffect(() => {
		const fetchPhotoUrls = async (photos) => {
			return Promise.all(
				photos.map(async (photo) => {
					const response = await axios.get(
						`https://www.getawai.online/get_photo_url`,
						{
							params: { photo_reference: photo.photo_reference },
						}
					);
					return response.data.photo_url; // url for the photo
				})
			);
		};

		const fetchWeatherData = async (lat, lon) => {
			try {
				const response = await axios.get(
					`https://api.open-meteo.com/v1/forecast`,
					{
						params: {
							latitude: lat,
							longitude: lon,
							daily: "temperature_2m_max,temperature_2m_min,precipitation_sum",
							timezone: "auto",
						},
					}
				);
				setWeatherData(response.data.daily);
			} catch (error) {
				console.error("Failed to fetch weather data:", error);
			}
		};

		const fetchTouristInfo = async (name) => {
			try {
				const response = await axios.get(
					`https://www.getawai.online/get_tourist_info`,
					{
						params: { attraction: name },
					}
				);
				return response.data.extract;
			} catch (error) {
				console.error("Failed to fetch tourist info:", error);
				return null;
			}
		};

		const fetchData = async () => {
			try {
				const response = await axios.get(
					`https://www.getawai.online/get_place_details?place_id=${placeId}`
				);
				const placeDetails = response.data.result;
				const photoUrls = await fetchPhotoUrls(placeDetails.photos);
				const touristInfo = await fetchTouristInfo(placeDetails.name);
				setTouristInfo(touristInfo);
				setPlaceDetails({ ...placeDetails, photos: photoUrls, touristInfo });
				const { lat, lng } = placeDetails.geometry.location;
				setDestinationLocation({ lat, lng }); // Set destination coordinates
				fetchWeatherData(lat, lng); // Fetch weather data
			} catch (error) {
				console.error("Failed to fetch place details:", error);
			}
		};

		fetchData();
	}, [placeId]);

	const handlePlanTripClick = () => {
		navigate("/planning", { state: { destinationTitle: placeDetails.name } });
	};

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
	};

	const getWeatherColor = (precipitation) => {
		if (precipitation > 10) {
			return "#a9a9a9"; // Grey for heavy rain
		} else if (precipitation > 0) {
			return "#87ceeb"; // Light blue for light rain
		} else {
			return "#87cefa"; // Sky blue for clear weather
		}
	};

	return (
		<div className="w-full min-h-screen bg-white dark:bg-black">
			<div className="destination-details-container ">
				{placeDetails ? (
					<Slider {...settings}>
						{placeDetails.photos?.map((url, index) => (
							<div key={index} className="carousel-image">
								<img
									src={url}
									alt={`${placeDetails.name} - view ${index + 1}`}
									style={{
										width: "100%",
										height: "auto",
										objectFit: "contain",
									}}
								/>
							</div>
						))}
					</Slider>
				) : (
					<div className="carousel-skeleton">
						<Skeleton className="h-64 w-full" />
					</div>
				)}

				<div className="destination-content w-full max-w-4xl mx-auto mb-8">
					{placeDetails ? (
						<>
							<h1 className="destination-title text-3xl font-bold mb-4 text-center">
								{placeDetails.name}
							</h1>
							<p className="destination-description mb-6 text-lg text-center">
								{touristInfo || "No description available."}
							</p>
						</>
					) : (
						<>
							<Skeleton className="h-8 w-1/2 mb-4 mx-auto" />
							<Skeleton className="h-6 w-full mb-6 mx-auto" />
						</>
					)}
					<div className="map-section mb-8">
						<h2 className=" text-black dark:text-white text-2xl font-semibold mb-4 text-center">
							Travel Map
						</h2>
						{destinationLocation ? (
							<div className="w-full max-w-2xl mx-auto h-80 rounded-md shadow-md overflow-hidden">
								<MapComponent
									userLocation={{
										lat: destinationLocation.lat,
										lng: destinationLocation.lng,
									}}
									markerType="destination"
								/>
							</div>
						) : (
							<Skeleton className="h-80 w-full max-w-2xl mx-auto rounded-md" />
						)}
					</div>

					{weatherData ? (
						<div className="weather-info w-full px-4 py-6 bg-gray-50 dark:bg-black dark:border-white rounded-lg shadow-md">
							<h2 className="text-2xl font-semibold text-center text-black dark:text-white mb-4">
								Weather Forecast
							</h2>
							<Carousel className="w-full max-w-md mx-auto">
								<CarouselContent>
									{weatherData.time.map((date, index) => (
										<CarouselItem key={index}>
											<Card
												style={{
													backgroundColor: getWeatherColor(
														weatherData.precipitation_sum[index]
													),
												}}
											>
												<CardContent>
													<div className="weather-card">
														<div className="weather-date">
															{format(new Date(date), "dd/MM/yyyy")}
														</div>
														<div className="weather-details">
															<span>
																Max Temp:{" "}
																{weatherData.temperature_2m_max[index]}°C
															</span>
															<span>
																Min Temp:{" "}
																{weatherData.temperature_2m_min[index]}°C
															</span>
															<span>
																Precipitation:{" "}
																{weatherData.precipitation_sum[index]} mm
															</span>
														</div>
													</div>
												</CardContent>
											</Card>
										</CarouselItem>
									))}
								</CarouselContent>
							</Carousel>
						</div>
					) : (
						<Skeleton className="h-40 w-full max-w-md mx-auto rounded-lg" />
					)}
				</div>
				<Button
					onClick={handlePlanTripClick}
					disabled={!placeDetails}
					className={`w-full px-4 py-3 ${
						placeDetails ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-400"
					} text-white font-semibold rounded-lg shadow-md transition-all duration-300 ease-in-out dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-300 focus:ring-opacity-75`}
				>
					{placeDetails
						? `Plan Your Trip to ${placeDetails.name}`
						: "Loading..."}
				</Button>
			</div>
			{/* <Footer></Footer> */}
		</div>
	);
};

export default DestinationDetails;
