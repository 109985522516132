import { Input } from "../ui/input";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
	InputOTPSeparator,
} from "../ui/input-otp";
import axios from "axios";
import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";

const OtpVerificationModal = ({
	isOpen,
	onClose,
	token,
	onItineraryVerified,
}) => {
	const [otp, setOtp] = useState("");
	const [email, setEmail] = useState(""); // New state for recipient's email
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleVerifyOtp = async () => {
		try {
			setLoading(true);
			const response = await axios.post("https://www.getawai.online/verify-otp", {
				email: email, // Email input
				otp: otp, // OTP input
				token: token, // The token extracted from the URL
			});

			if (response.data.success) {
				toast.success(
					"OTP Verified! You now have access to the itinerary. 🎉",
					{
						position: "top-center",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						theme: "light",
					}
				);
				onItineraryVerified(response.data.itinerary);
				onClose(); // Close the modal after success
			} else {
				toast.error("Invalid OTP. Please try again.", {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "light",
				});
				setError("Invalid OTP. Please try again.");
			}
		} catch (err) {
			toast.error("An error occurred while verifying OTP.", {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "light",
			});
			setError("An error occurred while verifying OTP.");
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			contentLabel="OTP Verification Modal"
			className="modal-content"
			overlayClassName="modal-overlay"
		>
			<div className="p-6 bg-white dark:bg-black text-black dark:text-white rounded-lg shadow-lg w-full max-w-md mx-auto border border-black dark:border-white">
				<h2 className="text-xl font-bold mb-4 text-center">OTP Verification</h2>

				{/* Email Input */}
				<Input
					type="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder="Enter your email"
					className="w-full p-2 mb-4 bg-white dark:bg-black border border-black dark:border-white text-black dark:text-white rounded"
				/>

				{/* OTP Input */}
				<div className="mb-4">
					<InputOTP maxLength={6} pattern={/^[A-Za-z0-9]+$/} onChange={setOtp}>
						<InputOTPGroup className="flex justify-between w-full">
							<InputOTPSlot
								index={0}
								className="bg-gray-100 dark:bg-black text-black dark:text-white border border-gray-300 dark:border-white p-2 rounded text-center w-10 h-10 mx-1"
							/>

							<InputOTPSlot
								index={1}
								className="bg-gray-100 dark:bg-black text-black dark:text-white border border-gray-300 dark:border-white p-2 rounded text-center w-10 h-10 mx-1"
							/>
							<InputOTPSlot
								index={2}
								className="bg-gray-100 dark:bg-black text-black dark:text-white border border-gray-300 dark:border-white p-2 rounded text-center w-10 h-10 mx-1"
							/>
							<InputOTPSeparator />
							<InputOTPSlot
								index={3}
								className="bg-gray-100 dark:bg-black text-black dark:text-white border border-gray-300 dark:border-white p-2 rounded text-center w-10 h-10 mx-1"
							/>
							<InputOTPSlot
								index={4}
								className="bg-gray-100 dark:bg-black text-black dark:text-white border border-gray-300 dark:border-white p-2 rounded text-center w-10 h-10 mx-1"
							/>
							<InputOTPSlot
								index={5}
								className="bg-gray-100 dark:bg-black text-black dark:text-white border border-gray-300 dark:border-white p-2 rounded text-center w-10 h-10 mx-1"
							/>
						</InputOTPGroup>
					</InputOTP>
				</div>

				{/* Error message */}
				{error && <p className="text-red-500 text-center mb-4">{error}</p>}

				{/* Verify Button */}
				<button
					onClick={handleVerifyOtp}
					className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition-all"
					disabled={loading}
				>
					{loading ? "Verifying..." : "Verify OTP"}
				</button>

				{/* Close Button */}
				<button
					onClick={onClose}
					className="w-full mt-4 bg-gray-300 dark:bg-gray-700 text-black dark:text-white py-2 rounded-md hover:bg-gray-400 dark:hover:bg-gray-600 transition-all"
				>
					Close
				</button>
			</div>

			<style jsx>{`
				.modal-overlay {
					background-color: rgba(0, 0, 0, 0.75);
				}
				.modal-content {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					max-width: 400px;
					width: 100%;
				}
			`}</style>
		</Modal>
	);
};

export default OtpVerificationModal;
