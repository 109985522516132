import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Input } from "../ui/input";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const GroupManagement = () => {
	const [groups, setGroups] = useState([]);
	const [newGroupName, setNewGroupName] = useState("");
	const [newGroupDescription, setNewGroupDescription] = useState("");
	// const navigate = useNavigate();

	useEffect(() => {
		const fetchGroups = async () => {
			try {
				const response = await axios.get(
					"https://www.getawai.online/get_groups",
					{
						withCredentials: true,
					}
				);
				setGroups(response.data.groups);
			} catch (error) {
				console.error("Failed to fetch groups:", error);
			}
		};

		fetchGroups();
	}, []);

	const createGroup = async () => {
		// Validate group name
		const trimmedGroupName = newGroupName.trim();
		if (trimmedGroupName.length < 2) {
			toast.error(
				"Group name must be at least 2 characters long without leading/trailing spaces."
			);
			return;
		}

		try {
			const response = await axios.post(
				"https://www.getawai.online/create_group",
				{ name: trimmedGroupName, description: newGroupDescription },
				{ withCredentials: true }
			);
			setGroups([
				...groups,
				{
					name: newGroupName,
					description: newGroupDescription,
					id: response.data.group_id,
				},
			]);
			setNewGroupName("");
			setNewGroupDescription("");
			toast.success("Group created successfully");
		} catch (error) {
			console.error("Failed to create group:", error);
			const errorMessage =
				error.response && error.response.data && error.response.data.message
					? error.response.data.message
					: "Failed to create group";
			toast.error(errorMessage);
		}
	};

	return (
		<div className="w-full min-h-screen flex flex-col justify-between bg-gray-50 dark:bg-black">
			<div className="container mx-auto p-4 flex-grow">
				<h1 className="text-3xl font-bold mb-6 text-gray-800 dark:text-white">
					Group Management
				</h1>
				<div className="mb-6 text-black dark:text-white flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4">
					<Input
						type="text"
						placeholder="Group Name"
						value={newGroupName}
						onChange={(e) => setNewGroupName(e.target.value)}
						className="w-full md:w-auto"
					/>
					<Input
						type="text"
						placeholder="Group Description"
						value={newGroupDescription}
						onChange={(e) => setNewGroupDescription(e.target.value)}
						className="w-full md:w-auto"
					/>
					<Button
						onClick={createGroup}
						className="w-full md:w-auto bg-blue-500"
					>
						Create Group
					</Button>
				</div>

				<div className="space-y-4 bg-gray-50 dark:bg-black">
					{groups.map((group) => (
						<Card
							key={group.id}
							className="shadow-md border w-full bg-gray-100 dark:bg-black"
						>
							<CardHeader>
								<CardTitle className="text-xl font-semibold text-gray-800 dark:text-white">
									{group.name}
								</CardTitle>
							</CardHeader>
							<CardContent>
								<p className="text-gray-600 dark:text-gray-300">
									{group.description}
								</p>
								<div className="mt-4 space-x-4">
									<Link
										to={`/group/${group.id}`}
										className="text-blue-500 hover:underline"
									>
										View Group
									</Link>
									<Link
										to={`/invite-to-group/${group.id}`}
										className="text-blue-500 hover:underline"
									>
										Invite Members
									</Link>
								</div>
							</CardContent>
						</Card>
					))}
				</div>
			</div>

			{/* Footer */}
			{/* <Footer /> */}
		</div>
	);
};

export default GroupManagement;
