import React from "react";
import Modal from "react-modal";

const WazeModal = ({ isOpen, onClose, wazeLinks }) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			ariaHideApp={false}
			className="fixed inset-0 flex items-center justify-center"
			overlayClassName="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
		>
			<div className="bg-white bg-opacity-80 backdrop-blur-lg rounded-lg shadow-lg p-6 w-full max-w-xs sm:max-w-md text-center">
				<h2 className="text-lg sm:text-xl font-bold mb-4 text-gray-900">
					Waze Route Segments
				</h2>
				<div className="space-y-4">
					{wazeLinks.map((link, index) => (
						<button
							key={index}
							onClick={() => window.open(link.url, "_blank")}
							className="w-full px-4 py-2 bg-blue-500 text-white rounded-full shadow hover:bg-blue-600 transition"
						>
							{link.label}
						</button>
					))}
				</div>
				<button
					onClick={onClose}
					className="mt-6 px-6 py-2 bg-red-500 text-white rounded-full shadow hover:bg-red-600 transition"
				>
					Close
				</button>
			</div>
		</Modal>
	);
};

export default WazeModal;
