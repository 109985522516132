// Routes.js

import ProtectedRoute from "./ProtectedRoute";
import AdminPage from "./components/AdminPage/AdminPage";
import DestinationDetails from "./components/DestinationDetails/DestinationDetails";
import GroupChat from "./components/GroupChat/GroupChat";
import GroupManagement from "./components/GroupManagement/GroupManagement";
import GroupMemberInvitation from "./components/GroupMemberInvitation/GroupMemberInvitation";
import ItinerariesPage from "./components/ItinerariesPage/ItinerariesPage";
import ItineraryPage from "./components/ItineraryPage/ItineraryPage";
import NavBar from "./components/NavBar/NavBar";
import PasswordReset from "./components/PasswordReset/PasswordReset";
import Socket from "./components/SocketTest";
import TripFormPage from "./components/TripForm/TripFormPage";
import UserProfile from "./components/UserProfile/UserProfile";
import HomePage from "./components/homopage/homopage";
import LoginPage from "./components/login/LogInPage";
import SignupPage from "./components/signuppage/signuppage";
import WelcomePage from "./components/welcomepage/WelcomePage";
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

function AppRoutes() {
	const location = useLocation();
	const showNavBar = !["/"].includes(location.pathname);

	return (
		<>
			{showNavBar && <NavBar />}
			<Routes>
				<Route path="/" element={<WelcomePage />} />
				<Route path="/signup" element={<SignupPage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/forgot-password" element={<PasswordReset />} />
				<Route
					path="/group-management"
					element={
						<ProtectedRoute>
							<GroupManagement />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/group/:groupId"
					element={
						<ProtectedRoute>
							<GroupChat />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/itinerary/:id"
					element={
						<ProtectedRoute>
							<ItineraryPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/itineraries"
					element={
						<ProtectedRoute>
							<ItinerariesPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/invite-to-group/:groupId"
					element={
						<ProtectedRoute>
							<GroupMemberInvitation />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/adminpage"
					element={
						<ProtectedRoute adminOnly={true}>
							<AdminPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/profile"
					element={
						<ProtectedRoute>
							<UserProfile />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/socket"
					element={
						<ProtectedRoute>
							<Socket />
						</ProtectedRoute>
					}
				/>
				<Route path="/view-itinerary/:token" element={<ItineraryPage />} />
				<Route
					path="/destination/:placeId"
					element={
						<ProtectedRoute>
							<DestinationDetails />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/planning"
					element={
						<ProtectedRoute>
							<TripFormPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/itinerary"
					element={
						<ProtectedRoute>
							<ItineraryPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/home"
					element={
						<ProtectedRoute>
							<HomePage />
						</ProtectedRoute>
					}
				/>
			</Routes>
		</>
	);
}

export default AppRoutes;
