import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./welcome.css";
import featureIcon1 from "../../images/bkk.jpeg";
import featureIcon3 from "../../images/bkk.jpeg";
import heroImage from "../../images/bkk.jpeg";
import featureIcon2 from "../../images/bkk.jpeg";
import NavBar from "../NavBar/NavBar";
import UserReviews from "../UserReviews/UserReviews";
import PaymentComponent from "../payment/PaymentComponent";

function WelcomePage() {
	useEffect(() => {
		const handleScroll = () => {
			const carElement = document.querySelector(".scrolling-car");
			const scrollPosition = window.scrollY;

			if (scrollPosition > 100) {
				// Start animation after scrolling 100px
				carElement.classList.add("animate");
			} else {
				carElement.classList.remove("animate");
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const navigate = useNavigate();

	const handleSignUpClick = () => {
		navigate("/signup");
	};

	const handleLogInClick = () => {
		navigate("/login");
	};

	const [selectedPlan, setSelectedPlan] = useState(null);

	const handlePlanSelect = (plan) => {
		setSelectedPlan(plan);
	};

	// Handle closing the payment form
	const closePaymentForm = () => {
		setSelectedPlan(null); // Close the payment form by resetting the selected plan
	};

	return (
		<div className="welcome-container">
			<NavBar />
			<header className="welcome-header">
				<div className="overlay"></div>
				<div className="hero-image parallax"></div>
				<img src={heroImage} alt="Hero" className="hero-image" />
				<div className="hero-text">
					<h1 className="welcome-title">
						Discover Your Ultimate Road Trip Planner
					</h1>
					<p className="welcome-subtitle">
						Plan, explore, and navigate with ease.
					</p>
					<div className="header-buttons">
						<button className="header-button" onClick={handleSignUpClick}>
							Plan it for me
						</button>
						<button className="header-button" onClick={handleLogInClick}>
							I want to plan it myself
						</button>
					</div>
				</div>
			</header>

			<section className="features-section parallax-section">
				<h2 className="features-title">Explore, Prep, Collaborate, Go</h2>
				<div className="features-grid glassy-cards">
					<div className="feature-card">
						<img src={featureIcon1} alt="Feature 1" className="feature-icon" />
						<h3 className="feature-title">Explore Hidden Gems</h3>
						<p className="feature-text">
							Discover places off the beaten path, curated just for you.
						</p>
					</div>
					<div className="feature-card">
						<img src={featureIcon2} alt="Feature 2" className="feature-icon" />
						<h3 className="feature-title">Real-Time Traffic Updates</h3>
						<p className="feature-text">
							Stay informed with live traffic updates and avoid delays.
						</p>
					</div>
					<div className="feature-card">
						<img src={featureIcon3} alt="Feature 3" className="feature-icon" />
						<h3 className="feature-title">Collaborate with Friends</h3>
						<p className="feature-text">
							Share your itinerary and make planning a breeze with your friends.
						</p>
					</div>
				</div>
			</section>

			<>
				{/* Plans Section */}
				<section className="plans-section parallax-section">
					<h2 className="plans-title">Choose the Right Plan for You</h2>
					<div className="plans-grid glassy-cards">
						<div className="plan-card">
							<h3 className="plan-name">Basic</h3>
							<p className="plan-price">$0/month</p>
							<ul className="plan-features">
								<li>5 Trips per Month</li>
								<li>Access to Basic Features</li>
								<br></br>
							</ul>
							<button
								className="plan-button"
								onClick={() => handlePlanSelect("Basic")}
							>
								Get Started
							</button>
						</div>
						<div className="plan-card">
							<h3 className="plan-name">Pro</h3>
							<p className="plan-price">$4.99/month</p>
							<ul className="plan-features">
								<li>Unlimited Trips</li>
								<li>Advanced Planning Tools</li>
								<li>Offline Maps</li>
							</ul>
							<button
								className="plan-button"
								onClick={() => handlePlanSelect("Pro")}
							>
								Upgrade now
							</button>
						</div>
						<div className="plan-card">
							<h3 className="plan-name">Premium</h3>
							<p className="plan-price">$9.99/month</p>
							<ul className="plan-features">
								<li>All Pro Features</li>
								<li>Priority Support</li>
								<li>Custom Routes and Navigation</li>
							</ul>
							<button
								className="plan-button"
								onClick={() => handlePlanSelect("Premium")}
							>
								Upgrade Now
							</button>
						</div>
					</div>
				</section>

				{/* PaymentComponent will only render if a plan is selected */}
				{selectedPlan && (
					<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30 dark:bg-opacity-75">
						<div className="bg-white dark:bg-black p-6 rounded-lg shadow-2xl max-w-lg w-full relative border border-gray-300 dark:border-gray-700">
							<h3 className="text-2xl text-center mb-4 text-gray-800 dark:text-white">
								Payment for {selectedPlan} Plan
							</h3>

							{/* Payment Component */}
							<PaymentComponent />

							{/* Close Button at the Bottom */}
							<button
								className="w-full bg-red-500 text-white p-2 rounded mt-6 hover:bg-red-600 transition-colors duration-300"
								onClick={closePaymentForm}
							>
								Close
							</button>
						</div>
					</div>
				)}
			</>
			<UserReviews />
			{/* <Footer /> */}
		</div>
	);
}

export default WelcomePage;
