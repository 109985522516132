import { useUser } from "../../UserContext";
import { Button } from "../ui/button.jsx";
import { Card, CardHeader, CardContent, CardFooter } from "../ui/card.jsx";
// Using the shadcn card component
import { Input } from "../ui/input.jsx";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function LoginPage() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();
	const { setCurrentUser } = useUser();

	const handleSubmit = async (event) => {
		event.preventDefault();
		const url = "https://www.getawai.online/api/login";

		try {
			const response = await axios.post(
				url,
				{ email, password },
				{
					withCredentials: true,
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			setCurrentUser(response.data.user);
			toast.success("Login successful!");

			setTimeout(() => {
				navigate("/home");
			}, 2000); // Delay (2 seconds)
		} catch (error) {
			toast.error(
				"Login failed due to bad credentials or user does not exist."
			);
		}
	};

	const handleGoogleLogin = () => {
		window.location.href = "https://www.getawai.online/auth/google";
	};

	return (
		<div className="min-h-screen flex flex-col justify-center items-center bg-white dark:bg-black text-black dark:text-white transition-colors duration-300">
			<Card className="w-full max-w-md bg-white dark:bg-black text-black dark:text-white shadow-md rounded-lg transition-colors duration-300">
				<CardHeader className="text-center">
					<h2 className="text-2xl font-bold">Login</h2>
				</CardHeader>
				<CardContent>
					<form onSubmit={handleSubmit} className="space-y-6">
						<Input
							type="email"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							className="w-full bg-gray-100 dark:bg-gray-700 dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
						/>
						<Input
							type="password"
							placeholder="Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
							className="w-full bg-gray-100 dark:bg-gray-700 dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
						/>
						<Button
							type="submit"
							className="w-full bg-blue-500 hover:bg-blue-600 text-white rounded-md py-2 transition-all duration-200"
						>
							Log In
						</Button>
					</form>

					<Button
						type="button"
						onClick={handleGoogleLogin}
						className="w-full mt-4 bg-red-600 hover:bg-red-700 text-white rounded-md py-2 transition-all duration-200 flex items-center justify-center"
					>
						<FontAwesomeIcon icon={faGoogle} className="mr-2" />
						Continue with Google
					</Button>
				</CardContent>

				<CardFooter className="flex justify-between mt-4">
					<Link
						to="/signup"
						className="text-sm text-blue-600 dark:text-blue-400 hover:underline hover:text-blue-700 dark:hover:text-blue-500 transition-all duration-200"
					>
						Don't have an account? <br></br>Sign up
					</Link>
					<Link
						to="/forgot-password"
						className="text-sm text-blue-600 dark:text-blue-400 hover:underline hover:text-blue-700 dark:hover:text-blue-500 transition-all duration-200"
					>
						Forgot your password?
					</Link>
				</CardFooter>
			</Card>
		</div>
	);
}

export default LoginPage;
