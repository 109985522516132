import { Button } from "../ui/button";
import { Card, CardHeader, CardContent, CardFooter } from "../ui/card";
import { Input } from "../ui/input";
import {
	Elements,
	CardElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useState } from "react";
import { FaCcVisa, FaCcMastercard, FaPaypal } from "react-icons/fa";
import { toast } from "react-toastify";

// Load Stripe Public Key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = () => {
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [paymentType, setPaymentType] = useState("visa");

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);

		if (!stripe || !elements) {
			return;
		}

		const cardElement = elements.getElement(CardElement);

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: cardElement,
			billing_details: {
				first_name: firstName,
				last_name: lastName,
			},
		});

		if (error) {
			setError(error.message);
			setLoading(false);
		} else {
			try {
				const { id } = paymentMethod;
				const response = await axios.post("/api/payment", { id, amount: 1000 }); // Replace 1000 with the amount to charge in cents

				if (response.data.success) {
					toast.success("Payment done.");
					setSuccess(true);
					setError(null);
				} else {
					toast.error("Payment failed.");
					setError("Payment failed");
				}
			} catch (error) {
				setError("Payment failed");
				toast.error("Payment failed.");
			} finally {
				setLoading(false);
			}
		}
	};

	return (
		<Card className="max-w-lg mx-auto mt-12 bg-white dark:bg-black text-black dark:text-white shadow-md rounded-lg transition-colors duration-300">
			<CardHeader className="text-center">
				<h2 className="text-3xl font-bold mb-4 dark:bg-black">
					Payment Details
				</h2>
			</CardHeader>
			<CardContent>
				<form onSubmit={handleSubmit} className="space-y-6 dark:bg-black">
					{/* First Name */}
					<div>
						<label
							htmlFor="firstName"
							className="block text-gray-700 dark:bg-black dark:text-gray-300 mb-2"
						>
							First Name
						</label>
						<Input
							type="text"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							placeholder="First Name"
							required
							className="w-full bg-gray-100 dark:bg-black dark:text-white border border-gray-300 dark:border-gray-600 rounded-md"
						/>
					</div>

					{/* Last Name */}
					<div>
						<label
							htmlFor="lastName"
							className="block text-gray-700 dark:bg-black dark:text-gray-300 mb-2"
						>
							Last Name
						</label>
						<Input
							type="text"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							placeholder="Last Name"
							required
							className="w-full bg-gray-100 dark:bg-black dark:text-white border border-gray-300 dark:border-gray-600 rounded-md"
						/>
					</div>

					{/* Payment Method */}
					<div>
						<label className="block text-gray-700 dark:text-gray-300 mb-2">
							Payment Method
						</label>
						<div className="flex space-x-3">
							{/* Visa Button */}
							<Button
								type="button"
								className={`w-full py-2 flex justify-center items-center transition-colors duration-300 ${
									paymentType === "visa"
										? "bg-blue-500 text-white hover:bg-blue-600"
										: "bg-gray-200 text-gray-700 dark:bg-gray-900 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600"
								} rounded-md`}
								onClick={() => setPaymentType("visa")}
							>
								<FaCcVisa className="mr-2" />
								Visa
							</Button>

							{/* MasterCard Button */}
							<Button
								type="button"
								className={`w-full py-2 flex justify-center items-center transition-colors duration-300 ${
									paymentType === "mastercard"
										? "bg-blue-500 text-white hover:bg-blue-600"
										: "bg-gray-200 text-gray-700 dark:bg-gray-900 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600"
								} rounded-md`}
								onClick={() => setPaymentType("mastercard")}
							>
								<FaCcMastercard className="mr-2" />
								MasterCard
							</Button>

							{/* PayPal Button */}
							<Button
								type="button"
								className={`w-full py-2 flex justify-center items-center transition-colors duration-300 ${
									paymentType === "paypal"
										? "bg-blue-500 text-white hover:bg-blue-600"
										: "bg-gray-200 text-gray-700 dark:bg-gray-900 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600"
								} rounded-md`}
								onClick={() => setPaymentType("paypal")}
							>
								<FaPaypal className="mr-2" />
								PayPal
							</Button>
						</div>
					</div>

					{/* Card Details */}
					<div>
						<label className="block text-gray-700 dark:bg-black dark:text-gray-300 mb-2">
							Card Details
						</label>
						<CardElement
							className="p-4 border border-gray-300 dark:border-gray-600  rounded-md bg-white dark:bg-black dark:text-white"
							options={{
								style: {
									base: {
										fontSize: "18px",
										color: "#000000",
										"::placeholder": {
											color: "#aab7c4",
										},
									},
									invalid: {
										color: "#9e2146",
									},
								},
							}}
						/>
					</div>

					{error && (
						<div className="text-red-500 text-center mb-4">{error}</div>
					)}
					{success && (
						<div className="text-green-500 text-center mb-4">
							Payment successful!
						</div>
					)}

					<Button
						type="submit"
						disabled={!stripe || loading}
						className={`w-full py-3 rounded-md text-white font-semibold ${
							loading ? "bg-gray-500" : "bg-blue-500 hover:bg-blue-600"
						} transition duration-300`}
					>
						{loading ? "Processing..." : "Pay"}
					</Button>
				</form>
			</CardContent>
			<CardFooter className="text-center">
				<p className="text-sm text-gray-500 dark:text-gray-400">
					We accept Visa, MasterCard, and PayPal.
				</p>
			</CardFooter>
		</Card>
	);
};

const PaymentComponent = () => {
	return (
		<Elements stripe={stripePromise}>
			<CheckoutForm />
		</Elements>
	);
};

export default PaymentComponent;
