import React, { useState, useEffect, useCallback } from "react";
import "./HomePage.css";
import MapComponent from "../MapComponent/MapComponent";
import NewsletterSignup from "../NewsletterSignup/NewsletterSignup";
import TopDestinations from "../TopDestinations/TopDestinations";
import SearchSection from "../searchSection/SearchSection";
import axios from "axios";

const HomePage = () => {
	const [searchQuery, setSearchQuery] = useState("");
	const [userLocation, setUserLocation] = useState(null);
	const [locationError, setLocationError] = useState(null);
	const [city, setCity] = useState("");
	const [userStreet, setUserStreet] = useState("");
	const [userState, setUserState] = useState("");
	const [userCountry, setUserCountry] = useState("");

	useEffect(() => {
		const initialSearch = "top tourist cities in Europe";
		setSearchQuery(initialSearch);
	}, []);

	const updateUserLocation = useCallback(async (lat, lng, city, street) => {
		try {
			const response = await axios.post(
				"https://www.getawai.online/updateUserLocation",
				{ lat, lng, city, street }, // Include street in the payload
				{ withCredentials: true }
			);
			if (response.status === 200) {
				console.log("User location updated successfully.");
				console.log("Location:", city, street);
			} else {
				console.error("Failed to update user location.");
			}
		} catch (error) {
			console.error("Failed to update user location:", error);
		}
	}, []);

	const fetchCityNameAndUpdateLocation = useCallback(
		async (lat, lng) => {
			try {
				const response = await axios.get(
					"https://nominatim.openstreetmap.org/reverse",
					{
						params: {
							lat,
							lon: lng,
							format: "json",
						},
					}
				);

				const address = response.data.address;
				const street = address.road || "Street not found";
				const city =
					address.city || address.town || address.village || "City not found";
				const state = address.state || "State not found";
				const country = address.country || "Country not found";

				setUserStreet(street); // Update the street state
				setUserState(state); // Update the state state
				setUserCountry(country); // Update the country state
				setCity(city);
				setUserLocation({ lat, lng });

				updateUserLocation(lat, lng, city, street);
			} catch (error) {
				console.error("Failed to fetch city name:", error);
			}
		},
		[updateUserLocation]
	);

	useEffect(() => {
		const fetchIpLocation = async () => {
			try {
				const response = await axios.get(
					"https://www.getawai.online/getIpLocation",
					{
						withCredentials: true,
					}
				);
				const { lat, lon, city } = response.data;
				console.log("IP Location Data:", { lat, lon, city });
				setUserLocation({ lat, lng: lon });
				setCity(city);
				updateUserLocation(lat, lon, city);
			} catch (error) {
				console.error("Failed to fetch IP location:", error);
			}
		};

		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const location = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};
					setUserLocation(location);
					console.log("Geolocation coordinates:", location); // Debugging output
					fetchCityNameAndUpdateLocation(location.lat, location.lng); // Fetch city name and update user location
				},
				(error) => {
					console.error(
						"Geolocation failed, falling back to IP-based location."
					);
					fetchIpLocation(); // Fallback to IP-based geolocation
				},
				{ enableHighAccuracy: true, timeout: 10000, maximumAge: 300000 }
			);
		} else {
			setLocationError("Geolocation is not supported by this browser.");
			fetchIpLocation(); // Fallback to IP-based geolocation
		}
	}, [fetchCityNameAndUpdateLocation, updateUserLocation]);

	const handleSearch = (query) => {
		setSearchQuery(query);
	};

	return (
		<div className="home-container bg-gray-50 dark:bg-black text-black dark:text-white transition-colors duration-300">
			<div className="content-wrapper space-y-8 last:mb-0 bg-gray-50 dark:bg-black pt-8 pb-0 px-8 rounded-md shadow-lg transition-colors duration-300">
				<SearchSection onSearch={handleSearch} />
				<TopDestinations searchQuery={searchQuery} />
				<hr className="border-t border-gray-300 dark:border-gray-600 my-8" />
				{locationError && (
					<p className="text-red-500 text-center">{locationError}</p>
				)}
				{/* {userLocation ? (
					<div className="text-center">
						<p>Your location:</p>
						<p>Latitude: {userLocation.lat}</p>
						<p>Longitude: {userLocation.lng}</p>
						<p>City: {city || "City not available"}</p>
						<p>Street: {userStreet || "Street not available"}</p>
						<p>State: {userState || "State not available"}</p>
						<p>Country: {userCountry || "Country not available"}</p>
					</div>
				) : (
					<p className="text-center">Fetching your location...</p>
				)} */}
				{city && <p className="text-center">Your city: {city}</p>}
				<MapComponent
					userLocation={userLocation}
					city={city}
					markerType="user"
				/>{" "}
				<hr className="border-t border-gray-300 dark:border-gray-600 my-8" />
				<FeaturedItineraries />
				<hr className="border-t border-gray-300 dark:border-gray-600 my-8" />
				<NewsletterSignup />
			</div>
		</div>
	);
};

const FeaturedItineraries = () => {
	return (
		<>
			<section className="featured-itineraries bg-gray-50 dark:bg-black text-gray-800 dark:text-white transition-colors duration-300 p-10 mt-10">
				<h2>Featured Itineraries</h2>
				<div className="itineraries-list">
					<article className="itinerary bg-gray-50 dark:bg-gray-400 text-gray-800 dark:text-gray-300 p-6 rounded-lg shadow-lg transition hover:shadow-xl">
						<img
							src="frontend/src/images/kemp.jpg"
							alt="Hoge Kempen National Park"
							className="itinerary-image"
						/>
						<h3>Hoge Kempen National Park: Belgium's Wild Heart</h3>
						<p>
							Drive to this hidden treasure in Limburg for stunning landscapes
							of heathland, pine forests, and tranquil lakes. Ideal for hiking,
							cycling, and taking in the raw beauty of Belgium's only national
							park.
						</p>
					</article>
					<article className="itinerary bg-gray-50 dark:bg-gray-400 text-gray-800 dark:text-gray-300 p-6 rounded-lg shadow-lg transition hover:shadow-xl">
						<img
							src="../../images/han.jpg"
							alt="Han-sur-Lesse"
							className="itinerary-image"
						/>
						<h3>Han-sur-Lesse: Caves and Wildlife</h3>
						<p>
							Explore the incredible underground caves and wildlife reserve in
							Han-sur-Lesse, located deep in the Ardennes. A perfect road trip
							for nature lovers and adventure seekers.
						</p>
					</article>

					<article className="itinerary bg-gray-50 dark:bg-gray-400 text-gray-800 dark:text-gray-300 p-6 rounded-lg shadow-lg transition hover:shadow-xl">
						<img
							src="../../images/plitv.jpg"
							alt="Plitvice Lakes"
							className="itinerary-image"
						/>
						<h3>Plitvice Lakes, Croatia: A Waterfall Wonderland</h3>
						<p>
							An epic road trip to Croatia’s Plitvice Lakes National Park is
							like stepping into a fairytale. Discover cascading waterfalls,
							lush greenery, and crystal-clear lakes on this unforgettable
							journey.
						</p>
					</article>
					<article className="itinerary bg-gray-50 dark:bg-gray-400 text-gray-800 dark:text-gray-300 p-6 rounded-lg shadow-lg transition hover:shadow-xl">
						<img
							src="../../images/tra.jpg"
							alt="Transfagarasan Highway"
							className="itinerary-image"
						/>
						<h3>Transfăgărășan Highway: The Road to the Clouds</h3>
						<p>
							Drive along the breathtaking Transfăgărășan Highway, often called
							"the best road in the world." This winding mountain road offers
							stunning views, dramatic landscapes, and access to incredible
							landmarks like the glacial Balea Lake and the ancient Poenari
							Fortress.
						</p>
					</article>

					<article className="itinerary bg-gray-50 dark:bg-gray-400 text-gray-800 dark:text-gray-300 p-6 rounded-lg shadow-lg transition hover:shadow-xl">
						<img
							src="../../images/meteo.jpg"
							alt="Meteora"
							className="itinerary-image"
						/>
						<h3>Meteora, Greece: Monasteries in the Sky</h3>
						<p>
							Road trip through the winding roads of central Greece to reach the
							breathtaking Meteora, where monasteries are perched atop giant
							rock pillars, creating a mystical landscape.
						</p>
					</article>
					<article className="itinerary bg-gray-50 dark:bg-gray-400 text-gray-800 dark:text-gray-300 p-6 rounded-lg shadow-lg transition hover:shadow-xl">
						<img
							src="../../images/lofo.jpg"
							alt="Lofoten Islands"
							className="itinerary-image"
						/>
						<h3>Lofoten Islands: Arctic Beauty Beyond the Ordinary</h3>
						<p>
							Embark on a road trip through the stunning Lofoten Islands in
							Norway, where towering mountains meet crystal-clear fjords and
							quaint fishing villages. Experience the midnight sun or the
							Northern Lights, and discover hidden beaches, hiking trails, and
							some of the most dramatic landscapes in the Arctic Circle.
						</p>
					</article>
				</div>
			</section>
		</>
	);
};

export default HomePage;
