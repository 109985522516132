import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import { Input } from "../ui/input";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
	InputOTPSeparator,
} from "../ui/input-otp";
import {
	faEye,
	faEyeSlash,
	faCheck,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

function PasswordReset() {
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [codeSent, setCodeSent] = useState(false);
	const [sendingCode, setSendingCode] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();

	const isLengthValid = newPassword.length >= 8;
	const hasUppercase = /[A-Z]/.test(newPassword);
	const hasLowercase = /[a-z]/.test(newPassword);
	const hasNumber = /[0-9]/.test(newPassword);
	const isValidPassword =
		isLengthValid && hasUppercase && hasLowercase && hasNumber;

	const requestReset = async () => {
		if (sendingCode) return; // prevent sending if already processing

		setSendingCode(true);
		try {
			await axios.post("https://www.getawai.online/request-reset", { email });
			toast.success("Code sent. Please check your email.");
			setCodeSent(true);
		} catch (error) {
			toast.error("Failed to send code. Please try again.");
		}
	};

	const resetPassword = async () => {
		if (!isValidPassword) {
			toast.error("Password does not meet the required criteria.");
			return;
		}

		try {
			await axios.post("https://www.getawai.online/reset-password", {
				email,
				code,
				newPassword,
			});
			toast.success("Password updated successfully.");
			navigate("/login");
		} catch (error) {
			if (error.response && error.response.data && error.response.data.error) {
				const backendErrors = error.response.data.error;
				if (Array.isArray(backendErrors)) {
					backendErrors.forEach((err) => toast.error(err));
				} else {
					toast.error(backendErrors);
				}
			} else {
				toast.error("Failed to reset password. Please try again.");
			}
		}
	};

	return (
		<div className="min-h-screen flex flex-col bg-gray-100 dark:bg-black">
			<div className="flex-grow flex justify-center items-center">
				<div className="max-w-md w-full space-y-8 dark:border-white">
					<div className="bg-white dark:bg-black rounded px-8 pt-6 pb-8 mb-4">
						<div className="mb-4 border border-black dark:border-white">
							<Input
								className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-black leading-tight focus:outline-none focus:shadow-outline ${
									codeSent && "opacity-50"
								}`}
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="Email"
								disabled={codeSent}
							/>
						</div>
						{!codeSent && (
							<div className="flex items-center justify-between">
								<button
									className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 w-full rounded focus:outline-none focus:shadow-outline"
									onClick={requestReset}
									disabled={sendingCode}
								>
									Send Code
								</button>
							</div>
						)}
						{codeSent && (
							<>
								<div className="mb-4">
									<InputOTP
										maxLength={6}
										pattern={/^[A-Za-z0-9]+$/}
										onChange={(otp) => setCode(otp)}
									>
										<InputOTPGroup className="flex justify-between w-full">
											<InputOTPSlot
												index={0}
												className="bg-gray-100 dark:bg-black text-black dark:text-white border border-gray-300 dark:border-white p-2 rounded text-center w-10 h-10 mx-1"
											/>
											<InputOTPSlot
												index={1}
												className="bg-gray-100 dark:bg-black text-black dark:text-white border border-gray-300 dark:border-white p-2 rounded text-center w-10 h-10 mx-1"
											/>
											<InputOTPSlot
												index={2}
												className="bg-gray-100 dark:bg-black text-black dark:text-white border border-gray-300 dark:border-white p-2 rounded text-center w-10 h-10 mx-1"
											/>
											<InputOTPSeparator className="text-black dark:text-white" />
											<InputOTPSlot
												index={3}
												className="bg-gray-100 dark:bg-black text-black dark:text-white border border-gray-300 dark:border-white p-2 rounded text-center w-10 h-10 mx-1"
											/>
											<InputOTPSlot
												index={4}
												className="bg-gray-100 dark:bg-black text-black dark:text-white border border-gray-300 dark:border-white p-2 rounded text-center w-10 h-10 mx-1"
											/>
											<InputOTPSlot
												index={5}
												className="bg-gray-100 dark:bg-black text-black dark:text-white border border-gray-300 dark:border-white p-2 rounded text-center w-10 h-10 mx-1"
											/>
										</InputOTPGroup>
									</InputOTP>
								</div>
								<div className="relative text-black dark:text-white">
									<Input
										type={showPassword ? "text" : "password"}
										placeholder="New Password"
										name="password"
										value={newPassword}
										onChange={(e) => setNewPassword(e.target.value)}
										required
										className="w-full bg-gray-100 dark:bg-black dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 pr-10"
									/>
									<button
										type="button"
										onClick={() => setShowPassword(!showPassword)}
										className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 dark:text-gray-400"
										aria-label={
											showPassword ? "Hide password" : "Show password"
										}
									>
										<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
									</button>
								</div>
								<div className="mt-2 mb-6 text-black dark:text-white">
									<p className="text-sm mb-1">Password must contain:</p>
									<ul className="list-none pl-0">
										<li className="flex items-center text-sm">
											<FontAwesomeIcon
												icon={isLengthValid ? faCheck : faTimes}
												className={`mr-2 ${
													isLengthValid ? "text-green-500" : "text-red-500"
												}`}
											/>
											At least 8 characters
										</li>
										<li className="flex items-center text-sm">
											<FontAwesomeIcon
												icon={hasUppercase ? faCheck : faTimes}
												className={`mr-2 ${
													hasUppercase ? "text-green-500" : "text-red-500"
												}`}
											/>
											At least one uppercase letter
										</li>
										<li className="flex items-center text-sm">
											<FontAwesomeIcon
												icon={hasLowercase ? faCheck : faTimes}
												className={`mr-2 ${
													hasLowercase ? "text-green-500" : "text-red-500"
												}`}
											/>
											At least one lowercase letter
										</li>
										<li className="flex items-center text-sm">
											<FontAwesomeIcon
												icon={hasNumber ? faCheck : faTimes}
												className={`mr-2 ${
													hasNumber ? "text-green-500" : "text-red-500"
												}`}
											/>
											At least one number
										</li>
									</ul>
								</div>
								<div className="flex items-center justify-between">
									<button
										className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 w-full rounded focus:outline-none focus:shadow-outline"
										onClick={resetPassword}
									>
										Reset Password
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default PasswordReset;
