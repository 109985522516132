import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import "./TripFormPage.css";
import { Input } from "../ui/input";

const steps = [
	"Trip Type",
	"Travel Type",
	"Date Range",
	"Detour Range",
	"Activity Range",
	"Daily Budget",
	"Accommodation",
	"Travel Interests",
	"Car",
	"Car Model",
	"Fuel Type",
	"Drive Style",
	"Total Budget",
];

const TripFormPage = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const destinationTitle = location.state?.destinationTitle;
	const [isFormDisabled, setIsFormDisabled] = useState(false);

	const [tripType, setTripType] = useState("one-way");
	const [travelType, setTravelType] = useState("solo");
	const [groupSize, setGroupSize] = useState(1);
	const [stayDuration, setStayDuration] = useState("");
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState();
	const [detourRange, setDetourRange] = useState(0);
	const [activityRangeKm, setActivityRangeKm] = useState(0);
	const [dailyBudget, setDailyBudget] = useState("");
	const [availableAccommodations, setAvailableAccommodations] = useState([]);
	const [selectedAccommodation, setSelectedAccommodation] = useState("");
	const [totalBudget, setTotalBudget] = useState(0);
	const [availableInterests, setAvailableInterests] = useState([]);
	const [selectedInterests, setSelectedInterests] = useState([]);

	const [car, setCar] = useState("");
	const [carModel, setCarModel] = useState("");
	const [carMakes, setCarMakes] = useState([]);
	const [carModels, setCarModels] = useState([]);
	const [fuelType, setFuelType] = useState("");
	const [driveStyle, setDriveStyle] = useState("");
	const [driveStyles, setDriveStyles] = useState([]);
	const [fuelTypes, setFuelTypes] = useState([]);

	const [step, setStep] = useState(1);
	const formRef = useRef(null);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === "Enter") {
				event.preventDefault(); // Prevent default form submission
				if (
					!destinationTitle ||
					!tripType ||
					!travelType ||
					!groupSize ||
					!stayDuration ||
					!startDate ||
					!endDate
				) {
					toast.error("Please fill in all required fields before submitting.");
				}
			}
		};

		const formElement = formRef.current;
		if (formElement) {
			formElement.addEventListener("keypress", handleKeyPress);
		}

		return () => {
			if (formElement) {
				formElement.removeEventListener("keypress", handleKeyPress);
			}
		};
	}, [
		destinationTitle,
		tripType,
		travelType,
		groupSize,
		stayDuration,
		startDate,
		endDate,
	]);

	useEffect(() => {
		const fetchCarMakes = async () => {
			try {
				const response = await fetch(
					"https://www.getawai.online/api/car-makes",
					{
						method: "GET",
						credentials: "include",
					}
				);
				const data = await response.json();
				console.log("Car Makes API Response:", data); // Log the response

				// Map to get only the necessary information
				const makes = data.Makes.map((make) => ({
					id: make.make_id,
					display: make.make_display,
				}));

				setCarMakes(makes); // Set the state to the new array
			} catch (error) {
				console.error("Failed to fetch car makes:", error);
			}
		};

		fetchCarMakes();
	}, []);

	const handleCarMakeChange = async (e) => {
		const selectedMake = e.target.value;
		setCar(selectedMake); // Set the selected car make
		setCarModel(""); // Reset car model when the car make changes

		if (selectedMake) {
			await fetchCarModels(selectedMake); // Fetch models when a make is selected
			setStep(10); // Move to the next step to show car models
		}
	};

	const fetchCarModels = async (make) => {
		try {
			const response = await fetch(
				`https://www.getawai.online/api/car-models?make=${make}`,
				{
					method: "GET",
					credentials: "include",
				}
			);
			const data = await response.json();
			console.log("Car Models API Response:", data); // Log the response

			setCarModels(data.Models || []); // Assuming the API returns the models in this format
		} catch (error) {
			console.error("Failed to fetch car models:", error);
		}
	};

	useEffect(() => {
		if (startDate && endDate) {
			const duration =
				Math.round((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
			setStayDuration(duration > 0 ? duration : 0);
		}
	}, [startDate, endDate]);

	useEffect(() => {
		const multiplier =
			travelType === "couple" ? 2 : travelType === "group" ? groupSize : 1;
		setTotalBudget(dailyBudget * stayDuration * multiplier);
	}, [dailyBudget, stayDuration, travelType, groupSize]);

	const fetchItems = async () => {
		try {
			const accommodationsResp = await axios.get(
				"https://www.getawai.online/api/getAccommodations",
				{ withCredentials: true }
			);
			const parsedAccommodations = accommodationsResp.data.map((item) =>
				JSON.parse(item)
			);
			setAvailableAccommodations(parsedAccommodations);

			const interestsResp = await axios.get(
				"https://www.getawai.online/api/getInterests",
				{ withCredentials: true }
			);
			const parsedInterests = interestsResp.data.map((item) =>
				JSON.parse(item)
			);
			setAvailableInterests(parsedInterests);

			const driveStylesResp = await axios.get(
				"https://www.getawai.online/api/getDrivingStyles",
				{ withCredentials: true }
			);
			const parsedDriveStyles = driveStylesResp.data.map((item) =>
				JSON.parse(item)
			);
			setDriveStyles(parsedDriveStyles);

			const fuelTypesResp = await axios.get(
				"https://www.getawai.online/api/getFuelTypes",
				{ withCredentials: true }
			);
			const parsedFuelTypes = fuelTypesResp.data.map((item) =>
				JSON.parse(item)
			);
			setFuelTypes(parsedFuelTypes);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		fetchItems();
	}, []);

	const handleInterestButtonClick = (interest) => {
		setSelectedInterests((prevSelected) => {
			const updated = { ...prevSelected };
			if (updated[interest]) {
				delete updated[interest];
			} else {
				updated[interest] = true;
			}
			return updated;
		});
	};

	const handleDateChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};

	const calculateMaxEndDate = () => {
		// If startDate is selected, calculate the max end date as 2 weeks (14 days) later
		if (startDate) {
			const maxEndDate = new Date(startDate);
			maxEndDate.setDate(maxEndDate.getDate() + 14); // Add 14 days
			return maxEndDate;
		}
		return null; // If no start date is selected, return null
	};

	const handleButtonClick = (setter, value) => {
		setter(value);
	};

	const pollForItinerary = async (itineraryId) => {
		const maxPollingTime = 5 * 60 * 1000; // Maximum polling time of 5 minutes
		const pollingStart = Date.now();

		const interval = setInterval(async () => {
			try {
				const statusResponse = await axios.get(
					`https://www.getawai.online/itinerary-status/${itineraryId}`,
					{ withCredentials: true }
				);

				if (statusResponse.data.itineraryReady) {
					clearInterval(interval);
					// Navigate to the itinerary page with the fetched data
					navigate("/itinerary", {
						state: {
							itineraryData: statusResponse.data.itinerary,
							destinationTitle,
						},
					});
					toast.success("Itinerary is ready!");
				} else if (statusResponse.data.status === "failed") {
					clearInterval(interval);
					toast.error(
						`Itinerary generation failed: ${statusResponse.data.error}`
					);
				} else {
					// Check if polling has exceeded the max time
					if (Date.now() - pollingStart > maxPollingTime) {
						clearInterval(interval);
						toast.error("Itinerary generation timed out. Please try again.");
					}
				}
			} catch (error) {
				toast.error("Error checking itinerary status: " + error.message);
			}
		}, 5000); // Poll every 5 seconds
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsFormDisabled(true);

		if (
			!destinationTitle ||
			!tripType ||
			!travelType ||
			!groupSize ||
			!stayDuration ||
			!startDate ||
			!endDate
		) {
			toast.error("Please fill in all required fields before submitting.");
			setIsFormDisabled(false);
			return;
		}

		console.log("Trip Type being submitted:", tripType);

		toast.info("Generating your itinerary...", {
			position: "top-center",
			autoClose: 10000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			theme: "light",
		});

		const formData = {
			destinationTitle,
			tripType,
			travelType,
			group_size: groupSize,
			stayDuration,
			startDate,
			endDate,
			activityRangeKm,
			detourRange,
			dailyBudget,
			accommodation: selectedAccommodation,
			travelInterests: selectedInterests,
			totalBudget,
			car,
			carModel,
			fuelType,
			driveStyle,
		};
		console.log("destination title in trip form " + destinationTitle);

		try {
			const response = await fetch(
				"https://www.getawai.online/generate-itinerary",
				{
					method: "POST",
					credentials: "include",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(formData),
				}
			);

			if (!response.ok) throw new Error("Network response was not ok.");

			const responseData = await response.json();
			const itineraryId = responseData.itineraryId;

			// Start polling for the itinerary status
			pollForItinerary(itineraryId);
		} catch (error) {
			console.error("Error:", error);
			toast.error("Failed to generate itinerary. Please try again.");
		} finally {
			setIsFormDisabled(false);
		}
	};

	const nextStep = () => {
		if (step === 3 && (!startDate || !endDate)) {
			toast.error("Please select a date range before proceeding.");
			return;
		}

		if (step === 6 && !dailyBudget) {
			toast.error("Please enter your daily budget before proceeding.");
			return;
		}

		if (step === 9 && !car) {
			toast.error("Please enter your car brand before proceeding.");
			return;
		}

		if (step === 10 && !carModel) {
			toast.error("Please enter your car model before proceeding.");
			return;
		}

		setStep((prevStep) => prevStep + 1);
	};

	const prevStep = () => {
		setStep((prevStep) => prevStep - 1);
	};

	const handleFieldChange = (event) => {
		const { name, value } = event.target;
		if (name === "trypType") setTripType(value);
		if (name === "travelType") setTravelType(value);

		if (name === "groupSize") {
			const numericValue = Number(value);
			if (value === "" || (numericValue >= 1 && numericValue <= 10)) {
				setGroupSize(numericValue); // Set the state if the input is valid
			}
		}

		if (name === "detourRange") {
			const numericValue = Number(value);
			if (value === "" || (numericValue >= 0 && numericValue <= 200)) {
				setDetourRange(value); // Set the state if the input is valid
			}
		}

		if (name === "activityRangeKm") {
			const numericValue = Number(value);
			if (value === "" || (numericValue >= 0 && numericValue <= 50)) {
				setActivityRangeKm(numericValue); // Set the state if the input is valid
			}
		}

		if (name === "dailyBudget") {
			const numericValue = Number(value);
			if (value === "" || (numericValue >= 0 && numericValue <= 1500)) {
				setDailyBudget(numericValue); // Set the state if the input is valid
			}
		}

		if (name === "selectedAccommodation") setSelectedAccommodation(value);
		if (name === "car") setCar(value);
		if (name === "carModel") setCarModel(value);
		if (name === "fuelType") setFuelType(value);
		if (name === "driveStyle") setDriveStyle(value);
	};

	return (
		<div className="trip-form-container max-w-2xl mx-auto my-16 p-8 border border-gray-300 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800">
			<h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100 text-center mb-8">
				Plan Your Trip to {destinationTitle}
			</h1>
			<div className="stepper flex flex-wrap justify-between mb-6">
				{steps.map((label, index) => (
					<div
						key={index}
						className={`relative flex-1 text-center py-2 border-b-4 
							${step === index + 1 ? "border-green-500 text-green-500" : ""}
							${
								step > index + 1
									? "border-green-500 text-green-500"
									: "text-gray-500 border-gray-300"
							}
						  `}
					>
						{label}
					</div>
				))}
			</div>
			<div className="form-wrapper ">
				<form onSubmit={handleSubmit} ref={formRef}>
					<fieldset disabled={isFormDisabled}>
						<TransitionGroup className="bg-gray-50 dark:bg-black text-black dark:text-white">
							<CSSTransition
								key={step}
								classNames="fade-vertical bg-gray-50 dark:bg-black text-black dark:text-white"
								timeout={2}
							>
								<div className="form-field bg-gray-50 dark:bg-black text-black dark:text-white">
									{step === 1 && (
										<div className="form-group">
											<label className="block font-semibold mb-2 text-black dark:text-white">
												Trip Type:
											</label>
											<div className="button-group flex gap-4 justify-center">
												{["one-way", "round"].map((type) => (
													<button
														type="button"
														key={type}
														className={`px-4 py-2 border rounded-full ${
															tripType === type
																? "bg-green-500 text-white"
																: "border-gray-300 text-gray-700 dark:text-gray-200 dark:border-gray-600"
														}`}
														onClick={() => handleButtonClick(setTripType, type)}
													>
														{type.charAt(0).toUpperCase() +
															type.slice(1).replace("-", " ")}
													</button>
												))}
											</div>
										</div>
									)}
									{step === 2 && (
										<div className="form-group">
											<label className="block font-semibold mb-2 text-black dark:text-white">
												Travel Type (max. 10 for groups):
											</label>
											<div className="button-group flex gap-4 justify-center">
												{["solo", "couple", "group"].map((type) => (
													<button
														type="button"
														key={type}
														className={`px-4 py-2 border rounded-full ${
															travelType === type
																? "bg-green-500 text-white"
																: "border-gray-300 text-gray-700 dark:text-white dark:border-gray-600"
														}`}
														onClick={() =>
															handleButtonClick(setTravelType, type)
														}
													>
														{type.charAt(0).toUpperCase() + type.slice(1)}
													</button>
												))}
											</div>
											{travelType === "group" && (
												<Input
													type="number"
													className="w-full mt-4 p-2 border text-black dark:text-white border-black dark:border-white dark:bg-black rounded"
													name="groupSize"
													placeholder="Group Size"
													value={groupSize}
													onChange={handleFieldChange}
													min="2"
													max="10"
													step="1"
												/>
											)}
										</div>
									)}
									{step === 3 && (
										<div className="form-group">
											<label className="block font-semibold mb-2 text-black dark:text-white">
												Date Range (max. 2 weeks):
											</label>
											<DatePicker
												selectsRange
												startDate={startDate}
												endDate={endDate}
												onChange={handleDateChange}
												onCalendarClose={nextStep}
												minDate={new Date()} // Disable past dates
												maxDate={calculateMaxEndDate()} // Set max end date based on start date
												dateFormat="dd/MM/yyyy"
												inline
											/>
										</div>
									)}
									{step === 4 && (
										<div className="form-group">
											<label className="block font-semibold mb-2 text-black dark:text-white">
												Comfortable detour range (max. 200 km)
											</label>
											<Input
												type="number"
												className="w-full mt-4 p-2 border text-black dark:text-white border-black dark:border-white dark:bg-black rounded"
												name="detourRange"
												value={detourRange}
												onChange={handleFieldChange}
												min="0"
												max="200"
												step="1"
											/>
										</div>
									)}
									{step === 5 && (
										<div className="form-group">
											<label className="block font-semibold mb-2 text-black dark:text-white">
												Find me attractions and activities within (max. 50 km)
												of the destination
											</label>
											<Input
												type="number"
												name="activityRangeKm"
												className="w-full mt-4 p-2 border text-black dark:text-white border-black dark:border-white dark:bg-black rounded"
												value={activityRangeKm}
												onChange={handleFieldChange}
												min="0"
												max="50"
												step="1"
											/>
										</div>
									)}
									{step === 6 && (
										<div className="form-group">
											<label className="block font-semibold mb-2 text-black dark:text-white">
												Daily budget per person (max. 1500):
											</label>
											<Input
												type="number"
												name="dailyBudget"
												className="w-full mt-4 p-2 border text-black dark:text-white border-black dark:border-white dark:bg-black rounded"
												value={dailyBudget}
												onChange={handleFieldChange}
												min="0"
												max="1500"
												step="1"
											/>
										</div>
									)}
									{step === 7 && (
										<div className="form-group">
											<label className="block font-semibold mb-2 text-black dark:text-white">
												Accommodation Preferences:
											</label>
											<div className="button-group">
												{availableAccommodations.map((acc) => (
													<button
														type="button"
														key={acc._id.$oid}
														className={`px-4 py-2 border rounded-full ${
															selectedAccommodation === acc.name
																? "bg-green-500 text-white"
																: "border-gray-300 text-gray-700 dark:text-gray-200 dark:border-gray-600"
														}`}
														onClick={() =>
															handleButtonClick(
																setSelectedAccommodation,
																acc.name
															)
														}
													>
														{acc.name}
													</button>
												))}
											</div>
										</div>
									)}
									{step === 8 && (
										<div className="form-group">
											<label className="block font-semibold mb-2 text-black dark:text-white">
												Travel Interests (select all that apply):
											</label>
											<div className="button-group">
												{availableInterests.map((interest) => (
													<button
														type="button"
														key={interest._id.$oid}
														className={`px-4 py-2 border rounded-full ${
															selectedInterests[interest.name]
																? "bg-green-500 text-white"
																: "border-gray-300 text-gray-700 dark:text-gray-200 dark:border-gray-600"
														}`}
														onClick={() =>
															handleInterestButtonClick(interest.name)
														}
													>
														{interest.name}
													</button>
												))}
											</div>
										</div>
									)}
									{step === 9 && (
										<div className="form-group">
											<label className="block font-semibold mb-2 text-black dark:text-white">
												Car:
											</label>
											<select
												value={car}
												onChange={handleCarMakeChange}
												className="w-full mt-4 p-2 border text-black dark:text-white border-black dark:border-white dark:bg-black rounded"
											>
												<option value="">Select Car Make</option>
												{carMakes.length > 0 ? (
													carMakes.map((make) => (
														<option key={make.id} value={make.id}>
															{make.display}{" "}
														</option>
													))
												) : (
													<option disabled>No car makes available</option>
												)}
											</select>
										</div>
									)}
									{step === 10 && (
										<div className="form-group">
											<label className="block font-semibold mb-2 text-black dark:text-white">
												Car Model:
											</label>
											<select
												value={carModel}
												onChange={(e) => setCarModel(e.target.value)}
												className="w-full mt-4 p-2 border text-black dark:text-white border-black dark:border-white dark:bg-black rounded"
											>
												<option value="">Select Car Model</option>
												{carModels.map((model) => (
													<option key={model.model_id} value={model.model_name}>
														{" "}
														{model.model_name}
													</option>
												))}
											</select>
										</div>
									)}
									{step === 11 && (
										<div className="form-group">
											<label className="block font-semibold mb-2 text-black dark:text-white">
												Type of Fuel:
											</label>
											<div className="button-group">
												{fuelTypes.map((type) => (
													<button
														type="button"
														key={type._id.$oid}
														className={`px-4 py-2 border rounded-full ${
															fuelType === type.name
																? "bg-green-500 text-white"
																: "border-gray-300 text-gray-700 dark:text-gray-200 dark:border-gray-600"
														}`}
														onClick={() =>
															handleButtonClick(setFuelType, type.name)
														}
													>
														{type.name}
													</button>
												))}
											</div>
										</div>
									)}
									{step === 12 && (
										<div className="form-group">
											<label className="block font-semibold mb-2 text-black dark:text-white">
												Drive Style:
											</label>
											<div className="button-group">
												{driveStyles.map((style) => (
													<button
														type="button"
														key={style._id.$oid}
														className={`px-4 py-2 border rounded-full ${
															driveStyle === style.name
																? "bg-green-500 text-white"
																: "border-gray-300 text-gray-700 dark:text-gray-200 dark:border-gray-600"
														}`}
														onClick={() =>
															handleButtonClick(setDriveStyle, style.name)
														}
													>
														{style.name}
													</button>
												))}
											</div>
										</div>
									)}
									{step === 13 && (
										<div className="form-group">
											<p className="block font-semibold mb-2 text-black dark:text-white">
												Total Budget: ${totalBudget.toFixed(2)}
											</p>
										</div>
									)}
									<div className="form-buttons flex justify-between px-6">
										{step > 1 && (
											<button
												type="button"
												className="back-button px-4 py-2 border border-gray-300 dark:border-gray-600 bg-green-500 text-white rounded"
												onClick={prevStep}
											>
												Back
											</button>
										)}
										{step < 13 && (
											<button
												type="button"
												className="next-button px-4 py-2 border border-gray-300 dark:border-gray-600 bg-green-500 text-white rounded ml-auto"
												onClick={nextStep}
											>
												Next
											</button>
										)}
										<br></br>
										{step === 13 && (
											<button
												type="submit"
												className="submit-btn px-8 py-2 border border-gray-300 dark:border-gray-600 bg-green-500 text-white rounded ml-6"
												disabled={
													!destinationTitle ||
													!tripType ||
													!travelType ||
													!groupSize ||
													!stayDuration ||
													!startDate ||
													!endDate ||
													isFormDisabled // Disable if the form is being submitted
												}
											>
												Plan my trip
											</button>
										)}
									</div>
								</div>
							</CSSTransition>
						</TransitionGroup>
					</fieldset>
				</form>
			</div>
		</div>
	);
};

export default TripFormPage;
